import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import Home from './pages/Home';
import AdminPortal from './pages/AdminPortal';
import SignUpRegister from './pages/SignUpRegister';
import SignInRegister from './pages/SignInRegister';
import Profile from './pages/Profile';
import { auth } from './firebase';
import LiveStreams from './pages/LiveStreams';
import Upgrade from './pages/Upgrade';
import Reservations from './pages/Reservations';
import './index.css'; // External CSS for styling

const App = () => {
  const [user, setUser] = useState();

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setUser(user);
    });
  }, []);

  return (
    <AuthProvider>
       {/* <div className="banner">
          TESTING PHASE, ANY PAYMENTS MADE WILL NOT BE REFUNDABLE
        </div> */}
      <div className="app-container">
        {/* Banner */}
       

        {/* Main Application Routes */}
        <Router>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/admin" element={<AdminPortal />} />
            <Route exact path="/signup" element={<SignUpRegister />} />
            <Route exact path="/signin" element={<SignInRegister />} />
            <Route exact path="/upgrade" element={<Upgrade />} />
            <Route exact path="/profile" element={<Profile />} />
            <Route exact path="/livestreams" element={<LiveStreams />} />
            <Route exact path="/reservations" element={<Reservations />} />
          </Routes>
        </Router>
      </div>
    </AuthProvider>
  );
};

export default App;
