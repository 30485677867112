import React, { useContext, useState, useEffect } from 'react';
import AuthContext from '../context/AuthContext';
import HeaderMin from '../components/HeaderMin';
import Footer from '../components/Footer';
import YouTubeStreams from '../components/YouTubeStreams';
import WeeklyCalendar from '../components/WeeklyCalendar';
import { TableProvider } from '../context/TableContext';
import TimeSlotBooking from '../components/TimeSlotBooking';
import BookedTables from '../components/BookedTables';
import LoadingSpinner from '../components/LoadingSpinner';
import Button from '../components/Button';
import OverlaidElements from '../components/OverlaidElements';

const Reservations = () => {
  const { user, isAuthenticated, loading } = useContext(AuthContext);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };

    // Attach event listener
    window.addEventListener('resize', handleResize);

    // Remove event listener on cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {user ? (
        <>
          {loading ? <LoadingSpinner /> : (
            <>
              <HeaderMin />
              <TableProvider>
                
                {isDesktop && <OverlaidElements />}
                <TimeSlotBooking />
              </TableProvider>
            </>
          )}
        </>
      ) : (
        <>
          <div style={{ justifyContent: 'center', alignItems: 'center', textAlign: 'center', fontSize: '2rem', marginTop: '25px' }}>
            YOU ARE NOT ADMIN
          </div>
          <div style={{ justifyContent: 'center', alignItems: 'center', textAlign: 'center', fontSize: '2rem', marginTop: '25px', borderRadius: '25px' }}>
            <button style={{ 
              background: 'linear-gradient(90deg, #1E3A8A, #2563EB)', 
              borderRadius: '25px', 
              padding: '10px 20px', 
              color: 'white', 
              border: 'none', 
              fontSize: '16px', 
              cursor: 'pointer', 
              transition: 'background 0.3s, transform 0.3s', 
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
              zIndex: '10'
            }}> 
              <a href="/" style={{ color: 'white', textDecoration: 'none' }}>Go back?</a>
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default Reservations;
