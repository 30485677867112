import React, { createContext, useReducer, useEffect, useState } from 'react';
// import axios from 'axios';
import { auth, db } from '../firebase';
import { onAuthStateChanged, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendEmailVerification, signOut } from 'firebase/auth';
import { setDoc, doc, getDoc, updateDoc } from 'firebase/firestore';
import { toast } from 'react-toastify';

const getErrorMessage = (errorMessage) => {
  const prefix = "Firebase: ";
  if (errorMessage.startsWith(prefix)) {
    return errorMessage.slice(prefix.length);
  }
  return errorMessage;
};

const AuthContext = createContext();

const authReducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload,
        error: null,
        notification: null,
        loading: false,
      };
    case 'REGISTER_SUCCESS':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload,
        error: null,
        notification: 'A verification email has been sent to you. Please verify to login.',
        loading: false,
      };
    case 'REGISTERED_VERIFY':
      return {
        ...state,
        error: 'A verification email has been sent to you. Please verify to login.',
        registryNotification: 'A verification email has been sent to you. Please verify to login.',
        loading: false,
      };
    case 'AUTH_ERROR':
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        error: action.payload,
        loading: false,
      };
    case 'REGISTER_ERROR':
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        registerError: action.payload,
        loading: false,
      };
    case 'LOGIN_ERROR':
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        loginError: action.payload,
        loading: false,
      };
    case 'SET_USER':
      return {
        ...state,
        isAuthenticated: !!action.payload,
        user: action.payload,
        loading: false,
      };
    case 'EMAIL_NOT_VERIFIED':
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        notification: 'Email is not verified. Please check your inbox for a verification email to login.',
        loading: false,
      };
    case 'LOADING':
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, {
    isAuthenticated: false,
    user: null,
    error: null,
    loading: false,
  });

  const [isRegistering, setIsRegistering] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        if (isRegistering) return; // Skip if registering
        dispatch({ type: 'LOADING' });
        const docRef = doc(db, "Users", user.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const userData = { uid: user.uid, emailVerified: user.emailVerified, ...docSnap.data() };
          dispatch({ type: 'SET_USER', payload: userData });
          if (!user.emailVerified) {
            dispatch({ type: 'EMAIL_NOT_VERIFIED' });
            await signOut(auth);
          }
        } else {
          console.log("No such document!");
        }
      } 
      // else {
      //   console.log("User not logged in");
      // }
    });

    return () => unsubscribe();
  }, [isRegistering]);

  const register = async (name, bod, address, phonenumber, email, password) => {
    dispatch({ type: 'LOADING' });
    setIsRegistering(true);
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      await setDoc(doc(db, 'Users', user.uid), {
        name,
        email,
        membershipType: 'free',
        membershipExpiration: null,
        bod,
        address,
        phonenumber,
        totalBookedHours: 0,
      });

      await sendEmailVerification(user);

      dispatch({ type: 'REGISTER_SUCCESS', payload: { uid: user.uid, name, email, membershipType: 'free', membershipExpiration: null } });
      alert('Verification email sent. Please check your inbox.');
      window.location.href = "/signin";
      
    } catch (err) {
      const errorMessage = getErrorMessage(err.message);
      dispatch({ type: 'REGISTER_ERROR', payload: errorMessage });
      alert(errorMessage);
    } finally {
      setIsRegistering(false);
    }
  };

  const login = async (email, password) => {
    dispatch({ type: 'LOADING' });
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      if (user.emailVerified) {
        const userDoc = await getDoc(doc(db, 'Users', user.uid));
        if (userDoc.exists()) {
          dispatch({ type: 'LOGIN_SUCCESS', payload: { uid: user.uid, ...userDoc.data() } });
          window.location.href = "/";
        }
      } else {
        dispatch({ type: 'LOGIN_ERROR', payload: "Email not verified" });
        await signOut(auth);
      }
    } catch (err) {
      const errorMessage = getErrorMessage(err.message);
      dispatch({ type: 'LOGIN_ERROR', payload: errorMessage });
      alert(errorMessage);
    }
  };

  const logout = async () => {
    try {
      console.log("trying to logout")
      await signOut(auth);
      dispatch({ type: 'LOGOUT' });
      localStorage.removeItem('user');
      sessionStorage.removeItem('user');
      window.location.href = '/';
    } catch (err) {
      dispatch({ type: 'AUTH_ERROR', payload: err.message });
      alert(err.message);
    }
  };

  const updateUserDetails = async (userId, newDetails) => {
    dispatch({ type: 'LOADING' });
    try {
      const userDocRef = doc(db, 'Users', userId);
      await updateDoc(userDocRef, newDetails);
      // Refresh user data after updating
      const docSnap = await getDoc(userDocRef);
      if (docSnap.exists()) {
        dispatch({ type: 'SET_USER', payload: { uid: userId, ...docSnap.data() } });
      }
    } catch (err) {
      console.error('Error updating user details:', err);
    }
  };

  return (
    <AuthContext.Provider value={{ ...state, register, login, logout, updateUserDetails }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
