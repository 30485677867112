import React, { useContext, useState } from 'react';
import AuthContext from '../context/AuthContext';
import {auth} from '../firebase'
import { toast } from 'react-toastify';
import { signInWithEmailAndPassword } from 'firebase/auth';



const Auth = ( { closeModal } ) => {
  const [errorMessage, setErrorMessage] = useState("");
  const { register, login, isAuthenticated } = useContext(AuthContext);
  const [isRegistering, setIsRegistering] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   if (isRegistering) {
  //     register(name, email, password);
  //   } else {
  //     login(email, password);
  //   }
  // };

    const handleSubmit = async (e) => {
    e.preventDefault();
    if (isRegistering) {
      register(name, email, password);
    } else {
      login(email, password);
      console.log("Login triggered")
    }
    //   try {
    //     await signInWithEmailAndPassword(auth, email, password)

    //     const user = auth.currentUser
    //     console.log("Logged In Successfully", user)
    //     toast.success("Logged In Successfully", {position: "top-center",})
        

    //   // window.location.href="/profile"`
    //   // fetchCurrentUser();
    // } catch (err) {
    //   toast.success(err.message, {position: "top-center"})
    //   console.log(err.message)

    //   const errorParts = err.message.split(" ");
    //   const filteredErrorMessage = errorParts.slice(1).join(" ");
    //   setErrorMessage(filteredErrorMessage);

    //   // setErrorMessage(err.message);
    //   // console.log(err)
      
    // }
    // }
  };



  
  return (
    
    <div className='modalBg'>
    <div className='modalContainer'>
    <div className="shadow__input"></div>
              <div className='titleCloseBtn'>
             <button onClick={() => closeModal(false)}>X</button>
             </div>

             <div className='title'>
             <h1>{isRegistering ? 'Register' : 'Login'}</h1>
             
             </div>

    <div className="body">
      <div className='credsInModal'>
      <p style={{ color: 'red' }}>{handleSubmit.error}</p>
      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        <form onSubmit={handleSubmit}>
          {isRegistering && (
            <div className='credstext'>
              
              {/* <div>Name</div> */}
              <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Name"
            />
            </div>
            
          )}
          <div className='credstext'>
            {/* <div>Email</div> */}
            
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
          />
          </div>
          <div className='credstext'>
            {/* <div>Password</div> */}
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
          />
          </div>
          <div className='footer'>
          <button  type="submit">{isRegistering ? 'Register' : 'Login'}</button>
                 
             </div>
          {/* <a style={{'cursor': 'pointer', 'fontSize': '1rem', 'color': 'cornflowerblue'}} onClick={() => setIsRegistering(!isRegistering)}>
        {isRegistering ? 'Already have an account? Login' : 'Don’t have an account? Register'}
      </a> */}
          
        </form>
        
      </div>
    </div>
    
    </div>
    </div>
  );
};

export default Auth;
