import React, { useContext } from 'react';
import AuthContext from '../context/AuthContext';
import TableContext, { TableProvider } from '../context/TableContext';
import Membership from '../components/Membership';
import Auth from '../components/Auth';
import Tables from '../components/Tables';
import Header from '../components/Header';
import ButtonGradient from "../assets/svg/ButtonGradient";
import Benefits from "../components/Benefits";
import Collaboration from "../components/Collaboration";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import Pricing from "../components/Pricing";
import Roadmap from "../components/Roadmap";
import Services from "../components/Services";
import OverlaidElements from '../components/OverlaidElements';
import LoginForm from '../components/LoginForm';
import HeaderMin from '../components/HeaderMin';

const Upgrade = () => {


  const { user, isAuthenticated, logout } = useContext(AuthContext);
  const authorizedEmail = 'support@billiardlounge.com'; // Replace with the specific email

  

  return (
    // <div className="pt-[4.75rem] lg:pt-[5.25rem] overflow-hidden">
    <>
    <HeaderMin />
      {user ? (
        <>
        <div style={{'paddingTop': '5vh'}}></div>
      <div style={{'display': 'flex', 'justifyContent': 'center'}}>
      <Membership />
      
      </div>
        <Footer />
        </>
      ) : (
        console.log("not allowed")
      )
      }
      </>
  );
};

export default Upgrade;
