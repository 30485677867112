import React from 'react';
import './BookingModal.css'; // Make sure you have some basic CSS for modal

const ScheduleModal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="modal-close" onClick={onClose}>X</button>
        {children}
      </div>
    </div>
  );
};

export default ScheduleModal;
