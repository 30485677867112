import { useEffect, useState } from "react";
import { MouseParallax } from "react-just-parallax";
import { balltrans, brainwave, brainwaveSymbol } from "../../assets";



import PlusSvg from "../../assets/svg/PlusSvg";

export const Gradient = () => {
  return (
    <>
      <div className="relative z-1 h-6 mx-2.5 bg-n-11 shadow-xl rounded-b-[1.25rem] lg:h-6 lg:mx-8" />
      <div className="relative z-1 h-6 mx-6 bg-n-11/70 shadow-xl rounded-b-[1.25rem] lg:h-6 lg:mx-20" />
    </>
  );
};

export const BottomLine = () => {
  return (
    <>
      <div className="hidden absolute top-[55.25rem] left-10 right-10 h-0.25 bg-n-6 pointer-events-none xl:block" />

      <PlusSvg className="hidden absolute top-[54.9375rem] left-[2.1875rem] z-2 pointer-events-none xl:block" />

      <PlusSvg className="hidden absolute top-[54.9375rem] right-[2.1875rem] z-2 pointer-events-none xl:block" />
    </>
  );
};

const Rings = () => {
  return (
    <>
      <div className="absolute top-1/2 left-1/2 w-[65.875rem] aspect-square border border-n-2/10 rounded-full -translate-x-1/2 -translate-y-1/2" />
      <div className="absolute top-1/2 left-1/2 w-[51.375rem] aspect-square border border-n-2/10 rounded-full -translate-x-1/2 -translate-y-1/2" />
      <div className="absolute top-1/2 left-1/2 w-[36.125rem] aspect-square border border-n-2/10 rounded-full -translate-x-1/2 -translate-y-1/2" />
      <div className="absolute top-1/2 left-1/2 w-[23.125rem] aspect-square border border-n-2/10 rounded-full -translate-x-1/2 -translate-y-1/2" />
    </>
  );
};

export const BackgroundCircles = ({ parallaxRef }) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <div className="absolute -top-[42.375rem] left-1/2 w-[78rem] aspect-square border border-n-2/5 rounded-full -translate-x-1/2 md:-top-[38.5rem] xl:-top-[32rem]">
      <Rings />

      {/* Moving background colored circle balls */}
      <MouseParallax strength={0.07} parallaxContainerRef={parallaxRef}>
        
        <img            style={{'position': 'absolute', 'top': '23%', 'left': '6%', 'opacity': '0.4'}}
                        className="mr-2.5"
                        src={balltrans}
                        width={16}
                        height={16}
                        alt={status}
                      />
        <img            style={{'position': 'absolute', 'top': '5%', 'left': '3%', 'rotate': '14deg', 'opacity': '0.5'}}
                        className="spin"
                        src={brainwaveSymbol}
                        width={20}
                        height={20}
                        alt={status}
                      />
        <img            style={{'position': 'absolute', 'top': '37%', 'left': '55%', 'rotate': '-2deg', 'opacity': '0.5'}}
                        className="spin"
                        src={brainwave}
                        width={50}
                        height={50}
                        alt={status}
                      />
        <img            style={{'position': 'absolute', 'top': '5%', 'left': '44%', 'rotate': '15deg', 'opacity': '0.5'}}
                        className="mr-2.5"
                        src={brainwave}
                        width={50}
                        height={50}
                        alt={status}
                      />
        <img            style={{'position': 'absolute', 'top': '7%', 'left': '93%', 'opacity': '0.8'}}
                        className="mr-2.5"
                        src={balltrans}
                        width={16}
                        height={16}
                        alt={status}
                      />
        

        <img            style={{'position': 'absolute', 'top': '45%', 'left': '1%', 'rotate': '-2deg', 'opacity': '0.5'}}
                        className="mr-2.5"
                        src={brainwaveSymbol}
                        width={50}
                        height={50}
                        alt={status}
                      />
        <img            style={{'position': 'absolute', 'top': '25%', 'right': '23%', 'opacity': '0.5'}}
                        className="mr-2.5"
                        src={balltrans}
                        width={35}
                        height={35}
                        alt={status}
                      />
                      <img            style={{'position': 'absolute', 'top': '35%', 'right': '73%', 'rotate': '40deg', 'opacity': '0.5'}}
                        
                        src={balltrans}
                        width={25}
                        height={25}
                        alt={status}
                      />
        <img            style={{'position': 'absolute', 'top': '35%', 'right': '3%', 'rotate': '14deg', 'opacity': '0.5'}}
                        className="spin"
                        src={brainwaveSymbol}
                        width={50}
                        height={50}
                        alt={status}
                      />
        
      </MouseParallax>
    </div>
  );
};
