import React, { useContext, useEffect, useState } from 'react';
import TableContext from '../context/TableContext';
import AuthContext from '../context/AuthContext';
import '../Tables.css';
import bookedImage from '../assets/booked.png';
import freeImage from '../assets/free.png';
import BookTable from './BookTable';
import { db } from '../firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { format, toZonedTime } from 'date-fns-tz';

const Tables = () => {
  const { tables, updateTableStatus, getTableStatus } = useContext(TableContext);
  const { user, isAuthenticated } = useContext(AuthContext);
  const authorizedEmail = 'syedosamaaskari@gmail.com'; // Replace with the specific email

  //===============================
  const timeZone = 'America/Toronto';
  // const formatString = 'yyyy-MM-dd HH:mm:ssXXX';
  const formatString = 'yyyy-MM-dd - HH:mm';
  const now = new Date();
  const zonedDate = toZonedTime(now, timeZone);
  const zonedDatePlusOneHour = new Date(zonedDate);
  zonedDatePlusOneHour.setHours(zonedDatePlusOneHour.getHours() + 1);
  const formattedTZDate = format(zonedDate, formatString, { timeZone });
  //===============================

  const [tableStatuses, setTableStatuses] = useState([]);
  const [scheduledBookings, setScheduledBookings] = useState({}); // State for scheduled bookings
  const [currentGlobalTime, setCurrentGlobalTime] = useState(null);
  const [currentCanadaTime, setCurrentCanadaTime] = useState(null);
  const [tableDurations, setTableDurations] = useState({}); // State to track duration for each table
  const [tableAdditionalPersons, setTableAdditionalPersons] = useState({}); // State for additional persons for each table
  const [isBookedForConfirmation, setIsBookedForConfirmation] = useState(false);
  

  useEffect(() => {
    const initializeTableStatuses = async () => {
      try {
        const initialStatuses = await Promise.all(tables.map(async table => {
          const { id, bookingStartTime, bookingDuration, lastBookingBy, bookedTemporarily } = table;
          const tableStatus = getTableStatus(id);
          const status = calculateTableStatus(bookingStartTime, bookingDuration, bookedTemporarily, tableStatus.bookedByUID);
          return { id, status, bookingStartTime, bookingDuration, lastBookingBy, bookedTemporarily, bookedByUID: tableStatus.bookedByUID };
        }));
        setTableStatuses(initialStatuses);
        fetchScheduledBookings(); // Fetch scheduled bookings after initializing table statuses
      } catch (error) {
        console.error('Error initializing table statuses:', error);
      }
    };

    initializeTableStatuses();
  }, [tables, getTableStatus]);

  useEffect(() => {
    const updateTableStatuses = async () => {
      try {
        const updatedStatuses = await Promise.all(tableStatuses.map(async table => {
          const { id, bookingStartTime, bookingDuration, bookedTemporarily } = table;
          const tableStatus = getTableStatus(id);
          const status = calculateTableStatus(bookingStartTime, bookingDuration, bookedTemporarily, tableStatus.bookedByUID);
          return { id, status, bookingStartTime, bookingDuration, bookedTemporarily, bookedByUID: tableStatus.bookedByUID };
        }));
        setTableStatuses(updatedStatuses);
        setCurrentGlobalTime(formattedTZDate)
        setCurrentCanadaTime(now)
      } catch (error) {
        console.error('Error updating table statuses:', error);
      }
    };

    const interval = setInterval(() => {
      updateTableStatuses();
    }, 1000); // Update every second

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, [tableStatuses, getTableStatus]);

  const fetchScheduledBookings = async () => {
    const today = formattedTZDate.split(' ')[0];
    const docRef = doc(db, 'scheduledBookings', today);
    const docSnap = await getDoc(docRef);
    

    if (docSnap.exists()) {
      setScheduledBookings(docSnap.data());
    } else {
      setScheduledBookings({});
    }
  };

  function formatTime(dateString) {
    const date = new Date(dateString);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  }

  const calculateTableStatus = (startTime, duration, bookedTemporarily, bookedByUID) => {
    const now = new Date();

    if (bookedTemporarily) {
      const tempEndTime = new Date(bookedTemporarily);
      if (now < tempEndTime) {
        return 'Temporarily Booked';
      }
    }

    if (!startTime || !duration) return 'free';

    const bookingEndTime = new Date(startTime);
    bookingEndTime.setHours(bookingEndTime.getHours() + duration);
    return now >= bookingEndTime ? 'free' : 'booked';
  };

  const handleStatusChange = async (id, currentStatus, bookedTemporarily) => {
    const now = new Date();
    const tempEndTime = new Date(bookedTemporarily);

    const tableDocRef = doc(db, 'tables', id);

    if (now < tempEndTime) {
      await updateDoc(tableDocRef, {
        status: 'free',
        bookedTemporarily: null,
        bookedByUID: null
      });
    } else {
      if (currentStatus === 'free') {
        await updateDoc(tableDocRef, {
          status: 'booked'
        });
      } else {
        await updateDoc(tableDocRef, {
          status: 'free',
          bookedTemporarily: null,
          bookedByUID: null
        });
      }
    }
  };

  const calculateTimeRemaining = (startTime, duration) => {
    const bookingEndTime = new Date(startTime);
    bookingEndTime.setMinutes(bookingEndTime.getMinutes() + (duration * 60)); // duration in hours
    const now = new Date();
    const timeDiff = bookingEndTime - now;
    if (timeDiff <= 0) return 'Expired';
    const hours = Math.floor(timeDiff / (1000 * 60 * 60));
    const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);
    return `${hours}h ${minutes}m ${seconds}s`;
  };

//   const getNextScheduledBookingAfterTime = (tableId) => {
//     const numericTableId = tableId
    
//     const tableBookings = Object.keys(scheduledBookings)
//       .filter(key => key.startsWith(`${numericTableId},`))
//       .map(key => ({
//         time: key.split(', ')[1],
//         status: scheduledBookings[key]
//       }));
      

//     const bookedSlots = tableBookings
//       .filter(booking => booking.status.startsWith('booked'))
//       .map(booking => ({
//         time: booking.time,
//         status: booking.status
//       }));

//     if (bookedSlots.length === 0) return 'free';
    

//     const now = new Date();
//     const zonedDate = toZonedTime(now, timeZone);

//     let nearestBooking = null;

//     if (bookedSlots.some(booking => {
//       const bookingTime = new Date(`${zonedDate.toISOString().split('T')[0]}T${booking.time}:00`);
//       return bookingTime > zonedDate;
//     })) 
//     {
//       nearestBooking = bookedSlots.reduce((nearest, booking) => {
//         const bookingTime = new Date(`${zonedDate.toISOString().split('T')[0]}T${booking.time}:00`);
//         if (bookingTime > zonedDate && (!nearest || bookingTime < nearest.bookingTime)) {
//           return { bookingTime, time: booking.time };
//         }
//         return nearest;
//       }, null);
//     }

//     return nearestBooking ? nearestBooking.bookingTime : 'free';
// };


const getNextScheduledBookingAfterTime = (tableId) => {
  const numericTableId = tableId;

  // Extract bookings related to the specific table
  const tableBookings = Object.keys(scheduledBookings)
      .filter(key => key.startsWith(`${numericTableId},`))
      .map(key => ({
          time: key.split(', ')[1], // Extract the time portion
          status: scheduledBookings[key]
      }));

  // Filter only booked slots
  const bookedSlots = tableBookings
      .filter(booking => booking.status.startsWith('booked'))
      .map(booking => ({
          time: booking.time,
          status: booking.status
      }));


  if (bookedSlots.length === 0) return 'free';

  // Current time in the specified timezone
  const now = new Date();
  const zonedDate = toZonedTime(now, timeZone);

  let nearestBooking = null;

  for (let booking of bookedSlots) {
      const bookingTime = new Date(`${zonedDate.toISOString().split('T')[0]}T${booking.time}:00`);


      // Only update nearestBooking if bookingTime is after the current time (`zonedDate`)
      // and it is closer than the currently selected nearestBooking
      if (bookingTime > zonedDate) {
          if (!nearestBooking || bookingTime < nearestBooking.bookingTime) {
              nearestBooking = { bookingTime, time: booking.time };
          }
      }
  }

  // Return the time of the nearest booking if found, otherwise return 'free'
  
  return nearestBooking ? nearestBooking.time : 'free'; 
};







// const getNextScheduledBookingAfterTime = (tableId) => {
//     // Assuming the tableId comes in the form of 'Table X', so using it as-is
//     const numericTableId = tableId
//     // console.log(numericTableId)

//     // Extract the current day for today's schedule (e.g., 2024-11-15)
//     const today = '2024-11-16';

//     // Filter all keys from the scheduledBookings object to find bookings for the specific table
//     const tableBookings = Object.keys(scheduledBookings)
//         .filter(key => key.startsWith(`${numericTableId},`))
//         .map(key => ({
//             time: key.split(', ')[1], // Extract the time (e.g., "23:00")
//             status: scheduledBookings[key] // Get the booking status (e.g., "booked, khawerkhan@gmail.com")
//         }));

//     // Filter out only the booked slots for this specific table
//     const bookedSlots = tableBookings
//         .filter(booking => booking.status.startsWith('booked'))
//         .map(booking => ({
//             time: booking.time,
//             status: booking.status
//         }));
      
//     // If there are no booked slots, return 'free'
//     if (bookedSlots.length === 0) return 'free';

//     // Get the current time to compare against booking times
//     const now = new Date();
//     const zonedDate = toZonedTime(now, 'America/Toronto');

//     // Find the next nearest booking time after the current time
//     let nearestBooking = bookedSlots.reduce((nearest, booking) => {
//         const bookingTime = new Date(`${today}T${booking.time}:00`); // Construct a Date object with today's date and booking time
//         if (bookingTime > zonedDate && (!nearest || bookingTime < nearest.bookingTime)) {
//             return { bookingTime, time: booking.time };
//         }
//         return nearest;
//     }, null);
//     console.log(nearestBooking)
//     // Return the nearest booking time if found, otherwise return 'free'
//     return nearestBooking ? nearestBooking.time : 'free';
// };


  const decrementDuration = (tableId) => {
    setTableDurations(prev => ({
      ...prev,
      [tableId]: Math.max((prev[tableId] || 1) - 1, 1)
    }));
  };

  const incrementAdditionalPersons = (tableId) => {
    setTableAdditionalPersons(prev => ({
      ...prev,
      [tableId]: Math.min((prev[tableId] || 0) + 1, 4) // Max 4 additional persons
    }));
  };

  const decrementAdditionalPersons = (tableId) => {
    setTableAdditionalPersons(prev => ({
      ...prev,
      [tableId]: Math.max((prev[tableId] || 0) - 1, 0) // Min 0 additional persons
    }));
  };

  return (
    <div>
      <div style={{textAlign: 'center'}}>
        
      </div>
      <div className="tables-container">
        {tables.map(table => {
          const nextBooking = getNextScheduledBookingAfterTime(table.id);
          
          //  console.log( "   check" , nextBooking)
          const currentCanadaTime = new Date(zonedDate);

          let tableStatus = table.status;
          // if (nextBooking !== 'free') {
          //   nextBooking.setHours(nextBooking.getHours() - 1);
          //   if (currentCanadaTime >= nextBooking) {
              
          //     tableStatus = 'booked';
          //   }
          // }
          if (nextBooking !== 'free') {
            const nextBookingTime = new Date(`${zonedDate.toISOString().split('T')[0]}T${nextBooking}:00`);
            // nextBookingTime.setHours(nextBookingTime.getHours()); // Adjust if needed
            nextBookingTime.setHours(nextBookingTime.getHours() - 1); // Adjust if needed
            if (currentCanadaTime >= nextBookingTime) {
                tableStatus = 'booked';
            }
        }
        

          const duration = tableDurations[table.id] || 1; // Get the duration for the specific table or default to 1
          const additionalPersons = tableAdditionalPersons[table.id] || 0; // Get the additional persons for the specific table or default to 0
          const uniqueKey = `${table.id}-${duration}-${additionalPersons}`;

          return (
            <div key={table.id} className="table" style={{ fontSize: '0.8rem', borderWidth: '1px', borderRadius: '20px', borderColor: '#ffffff4d' }}>
              {table.id.replace('_', ' ').replace(/^\w/, c => c.toUpperCase())}
              {isAuthenticated ? (
                <div style={{ display: 'inline' }}>
                  {tableStatus === 'free' && calculateTableStatus(table.startTime, table.duration, table.bookedTemporarily, table.bookedByUID) === 'free' ? (
                    <BookTable
                      key={uniqueKey}
                      tableId={table.id}
                      isTemporaryBooking={true}
                      duration={duration}
                      setDuration={newDuration => setTableDurations(prev => ({ ...prev, [table.id]: newDuration }))}
                      additionalPersons={additionalPersons}
                      setAdditionalPersons={newAdditionalPersons => setTableAdditionalPersons(prev => ({ ...prev, [table.id]: newAdditionalPersons }))}
                      isBookedForConfirmation={isBookedForConfirmation}
                      setIsBookedForConfirmation={setIsBookedForConfirmation}
                      incrementDuration={() => incrementDuration(table.id)}
                      decrementDuration={() => decrementDuration(table.id)}
                      incrementAdditionalPersons={() => incrementAdditionalPersons(table.id)}
                      decrementAdditionalPersons={() => decrementAdditionalPersons(table.id)}
                    />
                  ) : (
                    <>
                      {calculateTableStatus(table.startTime, table.duration, table.bookedTemporarily, table.bookedByUID) !== 'free' && table.bookedByUID === user.uid ? (
                        <>
                          <p style={{ color: 'green' }}>Temporarily Booked Until Payment Processed</p>
                          <BookTable
                            key={uniqueKey}
                            tableId={table.id}
                            isTemporaryBooking={false}
                            duration={duration}
                            setDuration={newDuration => setTableDurations(prev => ({ ...prev, [table.id]: newDuration }))}
                            additionalPersons={additionalPersons}
                            setAdditionalPersons={newAdditionalPersons => setTableAdditionalPersons(prev => ({ ...prev, [table.id]: newAdditionalPersons }))}
                            isBookedForConfirmation={isBookedForConfirmation}
                            setIsBookedForConfirmation={setIsBookedForConfirmation}
                            incrementDuration={() => incrementDuration(table.id)}
                            decrementDuration={() => decrementDuration(table.id)}
                            incrementAdditionalPersons={() => incrementAdditionalPersons(table.id)}
                            decrementAdditionalPersons={() => decrementAdditionalPersons(table.id)}
                          />
                        </>
                      ) : (
                        <div>
                          <label style={{ fontWeight: '500' }}>
                            {table.status === 'booked' && table.bookingStartTime && table.bookingDuration && (
                              <>
                                {calculateTimeRemaining(table.bookingStartTime, table.bookingDuration) !== 'Expired' ? (
                                  <>
                                    <div style={{margin: '10px'}}>
                                      <p>Time Left: {calculateTimeRemaining(table.bookingStartTime, table.bookingDuration)}</p>
                                    </div>
                                    <div style={{margin: '10px'}}>
                                      <p>In Progress</p>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div style={{margin: '10px'}}>
                                      <p>Available for reservation shortly </p>
                                    </div>
                                    <div style={{margin: '10px'}}>
                                      <p>Confirmation in Progress</p>
                                    </div>
                                  </>
                                )}
                              </>
                            )}
                            {user.email === authorizedEmail ? (
                              <>

                              </>
                            ) : (
                              <>
                               
                              </>
                            )}
                          </label>
                        </div>
                      )}
                    </>
                  )}
                </div>
              ) : (
                <></>
              )}
              <div>
                <img
                  src={tableStatus === 'free' ? freeImage : bookedImage}
                  alt={tableStatus === 'free' ? 'Free' : 'Booked'}
                />
              </div>
              {scheduledBookings && (
                <p>
                  {nextBooking !== 'free'
                    ? `This table is reserved at ${nextBooking}`
                    : 'Not Reserved'}
                </p>
              )}

            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Tables;
