import React, { useEffect, useState } from 'react';
import { db } from '../firebase';
import { collection, getDocs, updateDoc, doc } from 'firebase/firestore';

const UsersList = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expanded, setExpanded] = useState(false);
  const [editingUserId, setEditingUserId] = useState(null);
  const [editedMembership, setEditedMembership] = useState('');

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'Users'));
        const usersData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setUsers(usersData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching users:', error);
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const handleEditClick = (userId, currentMembership) => {
    setEditingUserId(userId);
    setEditedMembership(currentMembership);
  };

  const handleMembershipChange = (event) => {
    setEditedMembership(event.target.value);
  };

  const handleSaveClick = async (userId) => {
    try {
      const userDocRef = doc(db, 'Users', userId);
      await updateDoc(userDocRef, {
        membershipType: editedMembership,
      });

      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user.id === userId ? { ...user, membershipType: editedMembership } : user
        )
      );

      setEditingUserId(null);
    } catch (error) {
      console.error('Error updating membership:', error);
    }
  };

  const handleCancelClick = () => {
    setEditingUserId(null);
  };

  return (
    <div className="users-container">
      <h2 className="users-title">Registered Users</h2>
      <button className="users-toggle-button" onClick={toggleExpand}>
        {expanded ? 'Collapse' : 'Expand'} User List
      </button>
      {expanded && (
        <table className="users-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Membership</th>
              <th>Number</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user.id}>
                <td>{user.name}</td>
                <td>{user.email}</td>
                <td>
                  {editingUserId === user.id ? (
                    <select
                      value={editedMembership}
                      onChange={handleMembershipChange}
                    >
                      <option value="free">Free</option>
                      <option value="basic">Basic</option>
                      <option value="gold">Gold</option>
                      <option value="platinum">Platinum</option>
                    </select>
                  ) : (
                    user.membershipType
                  )}
                </td>
                <td>{user.phonenumber}</td>
                <td>
                  {editingUserId === user.id ? (
                    <>
                      <button style={{borderRadius: '2px', backgroundColor: '#0076d6', padding: '8px', margin: '5px'}} onClick={() => handleSaveClick(user.id)}>Save</button>
                      <button style={{borderRadius: '2px', backgroundColor: '#d800129c', padding: '8px', margin: '5px'}} onClick={handleCancelClick}>Cancel</button>
                    </>
                  ) : (
                    <button 
                      onClick={() => handleEditClick(user.id, user.membershipType)}
                    >
                      Edit
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default UsersList;
