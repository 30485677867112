import React, { createContext, useReducer, useEffect } from 'react';
import { db } from '../firebase'; // Adjust the import based on your file structure
import { doc, onSnapshot } from 'firebase/firestore';
import { format, toZonedTime } from 'date-fns-tz';

const TableContext = createContext();

const tableReducer = (state, action) => {
  switch (action.type) {
    case 'SET_TABLES':
      return {
        ...state,
        tables: action.payload,
        previousTables: state.tables.length ? state.tables : action.payload, // Store previous tables
      };
    case 'UPDATE_TABLE_STATUS':
      return {
        ...state,
        tables: state.tables.map((table) =>
          table.id === action.payload.id ? { ...table, status: action.payload.status } : table
        ),
      };
    default:
      return state;
  }
};

export const TableProvider = ({ children }) => {
  const [state, dispatch] = useReducer(tableReducer, {
    tables: [],
    previousTables: [], // Add previousTables to initial state
  });

  useEffect(() => {
    const updateTables = () => {
      const now = new Date();
      const timeZone = 'America/Toronto';
      const formatString = 'yyyy-MM-dd';
      
      const zonedTime = toZonedTime(now, timeZone);
      const zonedDate = toZonedTime(now, timeZone);
      const formattedDate = format(zonedDate, formatString, { timeZone });
      // console.log('Formatted date:', formattedDate); // Log the formatted date

      const currentHour = zonedTime.getHours().toString().padStart(2, '0') + ':00';
      // console.log('Current hour:', currentHour); // Log the current hour

      const scheduledBookingsDocRef = doc(db, 'scheduledBookings', formattedDate);

      const unsubscribe = onSnapshot(scheduledBookingsDocRef, (docSnap) => {
        if (docSnap.exists()) {
          const data = docSnap.data();
          // console.log('Scheduled bookings data:', data); // Log the document data

          let tables = [];

          for (const key in data) {
            const [table, timeSlot] = key.split(', ');

            if (timeSlot === currentHour) {
              const status = data[key].split(', ')[0]; // Get "booked" or "free"
              tables.push({ id: table, status });
              // console.log('Tables:', tables); // Log the tables that have been determined
            }
          }

          // Sort tables in ascending order by table id (e.g., Table 1, Table 2, ...)
          tables = tables.sort((a, b) => {
            const tableA = parseInt(a.id.replace('Table ', ''), 10);
            const tableB = parseInt(b.id.replace('Table ', ''), 10);
            return tableA - tableB;
          });

          // console.log('Final sorted tables array:', tables); // Log the final tables array after sorting
          dispatch({ type: 'SET_TABLES', payload: tables });
        } else {
          // console.log('No document found for date:', formattedDate); // Log if no document is found
          dispatch({ type: 'SET_TABLES', payload: [] });
        }
      });

      // Return the unsubscribe function for cleanup
      return unsubscribe;
    };

    // Call updateTables and get the unsubscribe function
    const unsubscribe = updateTables();

    // Cleanup on unmount
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, []);

  const updateTableStatus = async (id, status, duration = 0, user = {}) => {
    try {
      const tableDocRef = doc(db, 'tables', id);
      const updateData = {
        status,
        bookingStartTime: status === 'booked' ? new Date().toISOString() : null,
        bookingDuration: status === 'booked' ? duration : null,
        lastBookingBy: status === 'booked' ? `${user.email} - ${user.name}` : null,
      };
      await updateDoc(tableDocRef, updateData);
      dispatch({ type: 'UPDATE_TABLE_STATUS', payload: { id, status } });
    } catch (err) {
      console.error(err);
    }
  };

  const getTableStatus = (id) => {
    const currentTable = state.tables.find((table) => table.id === id);
    if (!currentTable) {
      return null;
    }

    const previousTable = state.previousTables.find((table) => table.id === id) || {};
    const bookedByUID = currentTable.bookedByUID || previousTable.bookedByUID;

    return {
      ...currentTable,
      bookedByUID,
    };
  };

  return (
    <TableContext.Provider value={{ ...state, updateTableStatus, getTableStatus }}>
      {children}
    </TableContext.Provider>
  );
};

export default TableContext;
