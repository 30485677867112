import { useLocation } from "react-router-dom";
import React, { useContext, useEffect } from 'react';
import { disablePageScroll, enablePageScroll } from "scroll-lock";
import AuthContext from '../context/AuthContext';
import { brainwave } from "../assets";
import { navigation } from "../constants";
import Button from "./Button";
import MenuSvg from "../assets/svg/MenuSvg";
import { HamburgerMenu } from "./design/Header";
import { useState } from "react";
import '../App.css'
import LoginModal from "./Login";
import Auth from "./Auth";
import {auth, db} from '../firebase'
import {fetchUserData} from '../context/AuthContext'

const Header = () => {

  const reloadCount = 0;

  const [openModal, setOpenModal] = useState(false)
  const [openNavigation, setOpenNavigation] = useState(false);
  const [currentGlobalTime, setCurrentGlobalTime] = useState(null)

  const { user, isAuthenticated, logout} = useContext(AuthContext);
  // const [userDetails, setUserDetails] = useState(null);

  const pathname = useLocation();
  
  async function handleLogout(){
    try {
      await auth.signOut();
      console.log("logged out")
      window.location.href="/"
    } catch (error) {
      alert("Error logging out:", error.message)
    }
  }

  // function refreshData() {
  //   const { user, isAuthenticated, logout } = useContext(AuthContext);
  // }

  const toggleNavigation = () => {
    if (openNavigation) {
      setOpenNavigation(false);
      enablePageScroll();
    } else {
      setOpenNavigation(true);
      disablePageScroll();
    }
  };

  

  const handleClick = () => {
    if (!openNavigation) return;

    enablePageScroll();
    setOpenNavigation(false);
  };

  

  return (
    
    <div
      // className={`fixed top-0 left-0 w-full z-50  border-b border-n-6 lg:bg-n-8/90 lg:backdrop-blur-sm ${
      //   openNavigation ? "bg-n-8" : "bg-n-8/90 backdrop-blur-sm"
      // }`}
      className={`fixed top-[0px] left-0 w-full z-50 border-b border-n-6 lg:bg-n-8/90 lg:backdrop-blur-sm ${
        openNavigation ? 'bg-n-8' : 'bg-n-8/90 backdrop-blur-sm'
      }`}
    >
      {!isAuthenticated && openModal && <Auth closeModal={setOpenModal}/>}
      <div className="flex items-center px-5 lg:px-7.5 xl:px-10 max-lg:py-4">
        <a className="block w-[12rem] xl:mr-8" href="#hero">
          <img className="bounce-in-right" src={brainwave} width={190} height={40} alt="BilliardLounge" style={{margin: '22px'}} />
        </a>

        <nav
          className={`${
            openNavigation ? "flex" : "hidden"
          } fixed top-[5rem] left-0 right-0 bottom-0 bg-n-8 lg:static lg:flex lg:mx-auto lg:bg-transparent`}
        >
         <div className="relative z-90 flex flex-col items-center justify-center m-auto lg:flex-row">
  {navigation.map((item) => (
    <a
      key={item.id}
      href={item.url}
      onClick={handleClick}
      className={`block relative font-code text-2xl uppercase text-n-1 transition-colors hover:text-color-1 ${
        item.onlyMobile ? "lg:hidden" : ""
      } px-6 py-2 md:py-4 lg:-mr-0.25 lg:text-xs lg:font-semibold ${
        item.url === pathname.hash ? "z-2 lg:text-n-1" : "lg:text-n-1/50"
      } lg:leading-5 lg:hover:text-n-1 xl:px-12`}
    >
      {item.title}
    </a>
  ))}

  {user ? (
    <>
      <a
        key={8}
        href="/livestreams"
        onClick={handleClick}
        className={`block relative font-code text-2xl uppercase text-n-3 transition-colors hover:text-color-1 ${
          false ? "lg:hidden" : ""
        } px-6 py-2 md:py-4 lg:-mr-0.25 lg:text-xs lg:font-semibold "z-2 lg:text-n-1"`}
      >
        <span
          className="fading-in-out hover:text-color-1"
          style={{
            color: 'red',
            fontWeight: '900'
          }}
        >
          •
        </span>{" "}
        Live Streams
      </a>
      <a
        key={5}
        href="/signin"
        onClick={handleClick}
        className={`block relative font-code text-2xl uppercase text-n-1 transition-colors hover:text-color-1 ${
          true ? "lg:hidden" : ""
        } px-6 py-2 md:py-4 lg:-mr-0.25 lg:text-xs lg:font-semibold "z-2 lg:text-n-1"`}
      >
        Your Profile
      </a>
      <a
      
        key={6}
        href="/signin"
        onClick={handleLogout}
        className={`block relative font-code text-2xl uppercase text-n-1 transition-colors hover:text-color-1 ${
          true ? "lg:hidden" : ""
        } px-6 py-2 md:py-4 lg:-mr-0.25 lg:text-xs lg:font-semibold "z-2 lg:text-n-1"`}
      >
        Log Out
      </a>
    </>
  ) : (
    <>
      <a
        key={4}
        href="/signup"
        onClick={handleClick}
        className={`block relative font-code text-2xl uppercase text-n-1 transition-colors hover:text-color-1 ${
          true ? "lg:hidden" : ""
        } px-6 py-2 md:py-4 lg:-mr-0.25 lg:text-xs lg:font-semibold "z-2 lg:text-n-1"`}
      >
        New Account
      </a>
      <a
        key={6}
        href="/signin"
        onClick={handleClick}
        className={`block relative font-code text-2xl uppercase text-n-1 transition-colors hover:text-color-1 ${
          true ? "lg:hidden" : ""
        } px-6 py-2 md:py-4 lg:-mr-0.25 lg:text-xs lg:font-semibold "z-2 lg:text-n-1"`}
      >
        Sign In
      </a>
    </>
  )}
</div>



              
            
          <HamburgerMenu />
        </nav>


        {user ? (
                
                <div className="flex items-center px-5 lg:px-7.5 xl:px-10 max-lg:py-4" >
                  {user.isAdmin ? (
                    <a style={{'fontWeight': '900', 'color': 'white'}}
                    href="/admin"
                    className="button hidden mr-8 text-n-1/50 transition-colors hover:text-n-1 lg:block"
                  >
                    Admin Dashboard
                  </a>
                   
                  

                  ) : ( 
                    <a style={{'fontWeight': '900', 'color': 'orange'}}
                    href="/profile"
                    className="button hidden mr-8 text-n-1/50 transition-colors hover:text-n-1 lg:block"
                  >
                    Welcome, {user.name}!
                  </a>
                  //     <button onClick={() => handleStatusChange(table.id, table.status)}>
                  // {table.status === 'free' ? 'Book' : 'Free'}
                  // </button>
                  )}
                   
                   <Button className="hidden lg:flex" onClick={handleLogout}>
                    Logout
                  </Button>
                
              
              </div >
              
            ) : ( <div className="flex items-center px-5 lg:px-7.5 xl:px-10 max-lg:py-4">
              <a
          href="/signup"
          className="button hidden mr-8 text-n-1/50 transition-colors hover:text-n-1 lg:block"
          // onClick={() => {setOpenModal(true)}}
          
        >
          New account {user}
        </a>
        <div className="subtlenotification">
        <Button className="hidden lg:flex" href={'/signin'}>
          Sign in
        </Button>
        </div>
                {/* //<Auth />
                console.log("notadmin") */}
            
            </div>)}


        {/* <a
          href="#signup"
          className="button hidden mr-8 text-n-1/50 transition-colors hover:text-n-1 lg:block"
        >
          New account
        </a>
        <div className="subtlenotification">
        <Button className="hidden lg:flex" href="#login">
          Sign in
        </Button>
        </div> */}


        <Button
          className="ml-auto lg:hidden"
          px="px-3"
          onClick={toggleNavigation}
        >
          <MenuSvg openNavigation={openNavigation} />
        </Button>
      </div>
    </div>
  );
};

export default Header;
