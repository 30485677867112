import React, { useContext, useState, useEffect } from 'react';
import TableContext from '../context/TableContext'; 
import AuthContext from '../context/AuthContext';
import { collection, addDoc, updateDoc, doc, increment, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import PayPalButton from './PayPalButton';
import BookingModal from './BookingModal'; 

const BookTable = ({
  tableId,
  isTemporaryBooking,
  duration,
  setDuration,
  additionalPersons,
  setAdditionalPersons,
  isBookedForConfirmation,
  setIsBookedForConfirmation,
  incrementDuration,
  decrementDuration,
  incrementAdditionalPersons,
  decrementAdditionalPersons
}) => {
  const { updateTableStatus, refreshTables } = useContext(TableContext);
  const { user } = useContext(AuthContext);
  const [showPayPalButton, setShowPayPalButton] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (showPayPalButton && !isTemporaryBooking) {
      finalizeBooking();
    }
  }, [showPayPalButton, isTemporaryBooking]);

  const handleTemporaryBooking = async () => {
    if (user) {
      const tableDocRef = doc(db, 'tables', tableId);
      const tableDoc = await getDoc(tableDocRef);

      if (tableDoc.exists() && tableDoc.data().status === 'free') {
        temporaryBooking();
      } else {
        setErrorMessage('The table is no longer available. Please try another table.');
      }
    } else {
      console.log("No user is logged in.");
    }
  };

  const temporaryBooking = async () => {
    const userInfo = {
      id: user.uid,
      name: user.name, // Adjust based on your user object
      email: user.email,
    };

    try {
      // Temporarily book the table for 2 minutes
      const bookingStartTime = new Date();
      const bookedTemporarily = new Date(bookingStartTime.getTime() + 2 * 60000); // 2 minutes from now
      await updateDoc(doc(db, 'tables', tableId), {
        bookedTemporarily: bookedTemporarily.toISOString(),
        bookedByUID: user.uid,
      });

      updateTableStatus(tableId, 'free', 2 / 60, userInfo); // We are not changing the status
      // refreshTables();
      setIsBookedForConfirmation(true);
    } catch (error) {
      console.error('Error with temporary booking:', error);
      setErrorMessage('Failed to temporarily book the table. Please try again.');
    }
  };

  const removeTemporaryBooking = async () => {
    try {
      await updateDoc(doc(db, 'tables', tableId), {
        bookedTemporarily: null,
        bookedByUID: null,
      });
      // refreshTables();
      setIsBookedForConfirmation(false);
    } catch (error) {
      console.error('Error removing booking:', error);
      setErrorMessage('Failed to remove temporary booked table. Please try again.');
    }
  };

  const finalizeBooking = async () => {
    const userInfo = {
      id: user.uid,
      name: user.name, // Adjust based on your user object
      email: user.email,
    };

    try {
      // Add booking record
      await addDoc(collection(db, 'bookings'), {
        userId: user.email + " - " + user.name,
        duration,
        additionalPersons,
        timestamp: new Date(),
      });

      // Update user's total booked hours
      const userRef = doc(db, 'Users', user.uid);
      await updateDoc(userRef, {
        totalBookedHours: increment(duration),
      });

      // Finalize the booking for the desired duration
      const bookingStartTime = new Date().toISOString();
      await updateDoc(doc(db, 'tables', tableId), {
        status: 'booked',
        bookingDuration: duration,
        bookingStartTime,
        additionalPersons,
        bookedTemporarily: null,
        bookedByUID: null,
      });

      updateTableStatus(tableId, 'booked', duration, userInfo);
      alert('Booking successful!');

      setIsBookedForConfirmation(false);
      setShowPayPalButton(false); // Reset PayPal button state
      setIsModalOpen(false); // Close the modal on success
    } catch (error) {
      console.error('Error finalizing booking:', error);
      alert('Failed to finalize booking:', error);
    }
  };

  const handlePayPalSuccess = (order) => {
    // console.log('Payment Successful:', order);
    finalizeBooking();
  };

  const calculateTotalAmount = () => {
    const baseRate = 24.86; // Assuming $22 + 13% tax per hour
    const additionalPersonRate = 2.1; // Additional charge per hour for an additional person
    const total = duration * (baseRate + (additionalPersons * additionalPersonRate));
    return total.toFixed(2); // Round to 2 decimal places
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  return (
    <div>
      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
      {!user.isAdmin ? (
        <>
          {isTemporaryBooking ? (
            <>
              {isBookedForConfirmation == false ? (
                <></>
                // <div style={{ 'fontWeight': '500' }}>
                //   <label>Hours: &nbsp;
                //     <button onClick={decrementDuration}>-</button>
                //     <span style={{ margin: '0 10px' }}>{duration}</span>
                //     <button onClick={incrementDuration}>+</button>
                //   </label>
                //   &nbsp;
                //   <div>
                //     <label>
                //       Extra Players:&nbsp;
                //       <button onClick={decrementAdditionalPersons}>-</button>
                //       <span style={{ margin: '0 10px' }}>{additionalPersons}</span>
                //       <button onClick={incrementAdditionalPersons}>+</button>
                //     </label>
                //   </div>
                // </div>
              ) : (
                <></>
                // <div style={{ 'fontWeight': '500' }}>
                //   <label>Hours: &nbsp;
                //     <span style={{ margin: '0 10px' }}>{duration}</span>
                //   </label>
                //   &nbsp;
                //   <div>
                //     <label>
                //       Extra Players:&nbsp;
                //       <span style={{ margin: '0 10px' }}>{additionalPersons}</span>
                //     </label>
                //   </div>
                // </div>
              )}
              {/* <button
                style={{ 'borderStyle': 'solid', 'backgroundColor': 'black', 'borderColor': 'green', 'borderWidth': '2px', 'borderRadius': '10% / 30%' }}
              >
              </button> */}
            </>
          ) : (
            <>
              {/* <div style={{ 'fontWeight': '500' }}>
                <label>Hours: &nbsp;
                  <button onClick={decrementDuration}>-</button>
                  <span style={{ margin: '0 10px' }}>{duration}</span>
                  <button onClick={incrementDuration}>+</button>
                </label>
                &nbsp;
                <div>
                  <label>
                    Extra Players:&nbsp;
                    <button onClick={decrementAdditionalPersons}>-</button>
                    <span style={{ margin: '0 10px' }}>{additionalPersons}</span>
                    <button onClick={incrementAdditionalPersons}>+</button>
                  </label>
                </div>
              </div>
              <BookingModal isOpen={isModalOpen} onClose={() => removeTemporaryBooking()}>
                <PayPalButton
                  amount={calculateTotalAmount()}
                  onSuccess={handlePayPalSuccess}
                />
              </BookingModal> */}
            </>
          )}
        </>
      ) : (
        <>
          {/* <div style={{ 'fontWeight': '500' }}>
            <label>Hours: &nbsp;
              <button onClick={decrementDuration}>-</button>
              <span style={{ margin: '0 10px' }}>{duration}</span>
              <button onClick={incrementDuration}>+</button>
            </label>
            &nbsp;
            <div>
              <label>
                Extra Players:&nbsp;
                <button onClick={decrementAdditionalPersons}>-</button>
                <span style={{ margin: '0 10px' }}>{additionalPersons}</span>
                <button onClick={incrementAdditionalPersons}>+</button>
              </label>
            </div>
          </div>
          <button
            style={{ 'borderStyle': 'solid', 'backgroundColor': 'black', 'borderColor': 'green', 'borderWidth': '2px', 'borderRadius': '10% / 30%' }}
            onClick={finalizeBooking}
          >
            Book Table
          </button> */}
        </>
      )}
    </div>
  );
};

export default BookTable;
