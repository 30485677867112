
import React from 'react';
import PropTypes from 'prop-types';

const YouTubeStreams = ({ stream }) => {
  if (!stream) return null;

  const { videoId } = stream;

  return (
    <div className="youtube-stream" style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', gap: '20px', padding: '50px'}}>
      <iframe
        width="560"
        height="315"
        src={`https://www.youtube.com/embed/${videoId}?modestbranding=1&rel=0&showinfo=0&controls=0&autoplay=1`}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        // style={{ pointerEvents: 'none' }}
      ></iframe>
    </div>
  );
};

YouTubeStreams.propTypes = {
  stream: PropTypes.shape({
    videoId: PropTypes.string.isRequired,
  }).isRequired,
};

export default YouTubeStreams;

