import React, { useContext, useState } from 'react';
import AuthContext from '../context/AuthContext';
import { brainwaveSymbol } from '../assets/index';
import Section from './Section';

const SignUpForm = ({ closeModal }) => {
  const { register, registryNotification, registerError } = useContext(AuthContext);
  const [name, setName] = useState('');
  const [bod, setBOD] = useState('');
  const [address, setAddress] = useState('');
  const [phonenumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const today = new Date();
  const maxDate = new Date(today.getFullYear() - 8, today.getMonth(), today.getDate()).toISOString().split('T')[0];
  const minDate = new Date(today.getFullYear() - 80, today.getMonth(), today.getDate()).toISOString().split('T')[0];

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage('');
    try {
      await register(name, bod, address, phonenumber, email, password);
    } catch (error) {
      setErrorMessage(error.message);
    }
    setLoading(false);
  };

  return (
    <Section>
      <div className='loginFormContainer'>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '80%',
          margin: '0 auto'
        }}>
          <div style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}>
            <img src={brainwaveSymbol} width={134} height={28} alt="Billiard Lounge" />
          </div>
          <div className='title' style={{ textAlign: 'center', marginTop: '20px', fontSize: '3rem' }}>
            <h1>Register</h1>
          </div>
        </div>
        <div className='body'>
          {registryNotification ? (
            <>
              <div style={{ color: 'orange' }}>
                {registryNotification}
              </div>
              <div><a href="/"> &nbsp; Click here to go back</a></div>
            </>
          ) : (
            <div className='credsInModal'>
              <form onSubmit={handleSubmit}>
                <div className='credstext'>
                  <div>Full Name</div>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="John Doe"
                    required
                  />
                </div>
                <div className='credstext'>
                  <div>Date of birth</div>
                  <input
                    type="date"
                    value={bod}
                    onChange={(e) => setBOD(e.target.value)}
                    placeholder="Date of birth"
                    min={minDate}
                    max={maxDate}
                    title="You can't be less than 8 years old or more than 80 years old! Please recheck the entered date"
                    required
                  />
                </div>
                <div className='credstext'>
                  <div>Address</div>
                  <input
                    type="text"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    placeholder="9215, Fay Road, 1002 Frami Terrace"
                    pattern=".{6,}"
                    title="Please enter a valid address"
                    required
                  />
                </div>
                <div className='credstext'>
                  <div>Phone Number</div>
                  <input
                    type="tel"
                    value={phonenumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    placeholder="+17037019964"
                    pattern="^[+]?[\d\s]{10,}$"
                    title="Phone number must be at least 10 digits and can include a + symbol."
                    required
                  />
                </div>
                <div className='credstext'>
                  <div>Email</div>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="john_doe@gmail.com"
                    required
                  />
                </div>
                <div className='credstext'>
                <div>Password</div>
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="password"
                    pattern=".{7,}"
                    title="Password must be at least 7 characters long."
                    required
                  />
                </div>
                {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
                <div className='footer'>
                  <button type="submit" disabled={loading}>
                    {loading ? 'Signing Up...' : 'Sign Up'}
                  </button>
                </div>
                {registerError && <div style={{ color: 'red' }}>{JSON.stringify(registerError)}</div>}
                <a style={{ cursor: 'pointer', fontSize: '1rem', color: 'cornflowerblue' }} href='/signin'>
                  Already have an account? Login
                </a>
              </form>
            </div>
          )}
        </div>
      </div>
    </Section>
  );
};

export default SignUpForm;
