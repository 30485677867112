import React from 'react';
import './CustomDateButton.css';

const CustomDateButton = ({ date, isSelected, onClick }) => {
  return (
    <button
      className={`custom-date-button${isSelected ? '-selected' : ''}`}
      onClick={() => onClick(date)}
    >
      {date.toDateString()}
      
    </button>
  );
};

export default CustomDateButton;
