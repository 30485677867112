
import { brainwaveSymbol, check } from "../assets";
import React, { useContext, useEffect, useState } from 'react';
import { collabApps, collabContent, collabText } from "../constants";
import Button from "./Button";
import Section from "./Section";
import { LeftCurve, RightCurve } from "./design/Collaboration";
import AuthContext from '../context/AuthContext';
import TableContext, { TableProvider } from '../context/TableContext';
import Auth from '../components/Auth';
import Tables from '../components/Tables';
import Membership from '../components/Membership';
import UserInfo from "./UserInfo";
import PayPalButton from "./PayPalButton";

import { format, toZonedTime } from 'date-fns-tz';

const Collaboration = () => {
  const { tables, updateTableStatus } = useContext(TableContext);
  const [freeTableCount, setFreeTableCount] = useState(0);
  
  const timeZone = 'America/Toronto';
  const formatString = 'yy-MM-dd - HH:mm';

  const [currentGlobalTime, setCurrentGlobalTime] = useState(null);

  const duration = 23.5
  const { user, isAuthenticated, logout } = useContext(AuthContext);

  useEffect(() => {
    const updateTime = async () => {
      const now = new Date();
      const zonedDate = toZonedTime(now, timeZone);
      const formattedTZDate = format(zonedDate, formatString, { timeZone });
        setCurrentGlobalTime(formattedTZDate)
    };

    const interval = setInterval(() => {
      updateTime();
    }, 10000); // Update every second

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  },);



  const processBooking = async () => {
    const userInfo = {
      id: user.uid,
      name: user.displayName || user.email, // Adjust based on your user object
      email: user.email,
    };

    try {
      // Add booking record
      await addDoc(collection(db, 'bookings'), {
        userId: user.email + " - " + user.displayName,
        duration,
        timestamp: new Date(),
      });

      // Update user's total booked hours
      const userRef = doc(db, 'Users', user.uid);
      await updateDoc(userRef, {
        totalBookedHours: increment(duration),
      });

      updateTableStatus(tableId, 'booked', duration, userInfo);
      alert('Booking successful!');
      setShowPayPalButton(false); // Reset PayPal button state
    } catch (error) {
      console.error('Error booking table:', error);
      alert('Failed to book table. Please try again.');
    }
  };

  const handlePayPalSuccess = (order) => {
    console.log('Payment Successful:', order);
    processBooking();
  };




  useEffect(() => {
    // Calculate the number of free tables
    const countFreeTables = tables.filter(table => table.status === 'free').length;
    setFreeTableCount(countFreeTables);
  }, [tables]);

  return (
    <Section crosses id="tables">
      
      <div className="container lg:flex ">
        <div className="max-w-[25rem]">
          <h2 className="h2 mb-4 md:mb-8">
            Live Table Data
          </h2>
          
          <ul className="max-w-[22rem] mb-10 md:mb-14">
            {collabContent.map((item) => (
              <li className="mb-3 py-3" key={item.id}>
                <div className="flex items-center">
                  <img src={check} width={24} height={24} alt="check" />
                  <h6 className="body-2 ml-5">{item.title}</h6>
                </div>
                {item.text && (
                  <p className="body-2 mt-3 text-n-4">All data shown on website is actual data from the lounge and is based on Toronto Timezone which is currently
                  {currentGlobalTime && 
                    <span style={{'fontWeight': '300', 'color': 'white', 'fontWeight': '900'}}> {JSON.stringify(currentGlobalTime).split(' - ')[1].replace('"', '')}. </span> 
                  }
                   Please refresh the page for accurate real time updates.<span style={{'fontWeight': '300', 'color': 'white'}}> we currently have {freeTableCount} free tables.</span></p>
                )} 
              </li>
            ))}
          </ul>

          

          {/* <Button>Become a Member for Online Reservation</Button> */}
        </div>

       

        <div className="lg:ml-auto xl:w-[38rem] mt-4">
          
        
          <div className="relative left-1/2 flex w-[22rem] aspect-square border border-n-6 rounded-full -translate-x-1/2 scale:75 md:scale-100">
          
            <div className="flex w-60 aspect-square m-auto border border-n-6 rounded-full">
            <p className='free-tables-count' style={{'position': 'absolute', 'fontSize': '1.8rem'}}>
          {freeTableCount} TABLES ARE FREE.
          </p>
              <div className="w-[6rem] aspect-square m-auto p-[0.2rem] bg-conic-gradient rounded-full">
                
                <div className="flex items-center justify-center w-full h-full bg-n-8 rounded-full">
                  
                  <img
                    src={brainwaveSymbol}
                    width={48}
                    height={48}
                    alt="brainwave"
                  />
                </div>
                
              </div>
              
            </div>
                
            <ul>
              {collabApps.map((app, index) => (
                <li
                  key={app.id}
                  className={`absolute top-0 left-1/2 h-1/2 -ml-[1.6rem] origin-bottom rotate-${
                    index * 45
                  }`}
                >
                  {/* <div
                    className={`relative -top-[1.6rem] flex w-[3.2rem] h-[3.2rem] bg-n-7 border border-n-1/15 rounded-xl -rotate-${
                      index * 45
                    }`}
                  >
                    <img
                      className="m-auto"
                      width={app.width}
                      height={app.height}
                      alt={app.title}
                      src={app.icon}
                    />
                  </div> */}
                </li>
              ))}
            </ul>
            
            <LeftCurve />
            <RightCurve />
          </div>
          {/* Add tables here  */}
        </div> 
        <div style={{ 
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              maxWidth: '90vw',
              margin: '0 auto'
          }}>
            
            {isAuthenticated ? (
              
              <button style={{ 
                background: 'linear-gradient(90deg, #ffbb00, #ebe825)', 
                borderRadius: '25px', 
                padding: '10px 20px', 
                color: 'black', 
                border: 'none', 
                fontSize: '16px', 
                fontWeight: 'bold',
                cursor: 'pointer', 
                transition: 'background 0.3s, transform 0.3s', 
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                zIndex: '10'
              }}> <a href="/reservations">Reserve for later?</a>
                
              </button>
              
            ):(
              // <Button href={"/tables"}>Become A Member For Online Reservations</Button> 
              <button style={{ 
                background: 'linear-gradient(90deg, #ffbb00, #ebe825)', 
                borderRadius: '25px', 
                padding: '10px 20px', 
                color: 'black', 
                border: 'none', 
                fontSize: '16px', 
                fontWeight: 'bold',
                cursor: 'pointer', 
                transition: 'background 0.3s, transform 0.3s', 
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
              }} >
                <a href="/signup">Become a Member for Online Reservations</a>
                
              </button>
            )
              
            }
            
          </div>
      </div>
      
        <div >
          {isAuthenticated ? (
            <>
            
            <TableProvider>
              
            <Tables />
          </TableProvider>
          </>
          ) : (
            
            <div style={{ 
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              maxWidth: '90vw',
              margin: '0 auto'
          }}>
            {user ? (
              <Button href={"/signup"}>Book a table?</Button> 
            ) : (
              <></>
            )}
          </div>
          )}
          
          </div>
      {/* {isAuthenticated ? (
            <div>
              <p>Welcome, {user.name}!</p>
              <button onClick={logout}>Logout</button>
            </div>
          ) : (
            <Auth />
          )} */}
          
    </Section>
  );
};

export default Collaboration;
