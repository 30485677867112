import React, { useContext } from 'react';
import AuthContext from '../context/AuthContext';
import Footer from "../components/Footer";
import LoginForm from '../components/LoginForm';
import LoadingSpinner from '../components/LoadingSpinner';

const SignInRegister = () => {
  const { user, isAuthenticated, loading } = useContext(AuthContext);

  const handleReturnHome = () => {
    window.location.href = '/';
  };

  return (
    <div>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div>
          {isAuthenticated ? (
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
              <h2>You are already logged in</h2>
              <button
                style={{
                  backgroundColor: '#4800ff',
                  color: 'black',
                  padding: '10px 20px',
                  border: 'none',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  marginTop: '20px'
                }}
                onClick={handleReturnHome}
              >
                Return to Home
              </button>
            </div>
          ) : (
            <LoginForm />
          )}
        </div>
      )}
      <Footer />
    </div>
  );
};

export default SignInRegister;
