import React, { useContext } from 'react';
import AuthContext from '../context/AuthContext';
import HeaderMin from '../components/HeaderMin';
import Footer from '../components/Footer';
import Button from "../components/Button";

const Profile = () => {
  const { user } = useContext(AuthContext);

  async function handleLogout() {
    try {
      await auth.signOut();
      console.log("logged out");
      window.location.href = "/";
    } catch (error) {
      console.error("Error logging out:", error.message);
    }
  }

  return (
    <div className="pt-[4.75rem] lg:pt-[5.25rem] overflow-hidden min-h-screen flex flex-col">
      {user ? (
        <>
          <HeaderMin />

          <div className="flex flex-col items-center justify-center mt-10 px-4 md:px-0">
            <div style={{backgroundColor: '#ffffff10'}} className=" rounded-lg shadow-lg w-full md:w-2/3 lg:w-1/2 p-6">
              <h2 className="text-3xl font-bold text-white-800 text-center mb-4">
                Welcome, {user.name}
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="text-white-600 font-semibold">Email:</div>
                <div className="text-white-800">{user.email}</div>

                <div className="text-white-600 font-semibold">Verified:</div>
                <div className="text-white-800">{user.emailVerified ? "Yes" : "No"}</div>

                <div className="text-white-600 font-semibold">Membership:</div>
                <div className="text-white-800">{user.membershipType}</div>

                <div className="text-white-600 font-semibold">Membership Expiry:</div>
                <div className="text-white-800">
                  {user.membershipExpiration ? user.membershipExpiration : 'Free Plan (No Expiry)'}
                </div>

                <div className="text-white-600 font-semibold">Address:</div>
                <div className="text-white-800">{user.address}</div>

                <div className="text-white-600 font-semibold">Phone Number:</div>
                <div className="text-white-800">{user.phonenumber}</div>

                <div className="text-white-600 font-semibold">Date of Birth:</div>
                <div className="text-white-800">{user.bod}</div>
              </div>

              <div className="mt-6 flex justify-center">
                <Button onClick={handleLogout} className="date-nav-button">
                  Logout
                </Button>
              </div>
            </div>
          </div>

          <Footer />
        </>
      ) : (
        <div className="flex items-center justify-center min-h-screen text-2xl font-semibold">
          YOU ARE NOT LOGGED IN
        </div>
      )}
    </div>
  );
};

export default Profile;
