import React, { useContext } from 'react';
import AuthContext from '../context/AuthContext';
import TableContext, { TableProvider } from '../context/TableContext';
import Membership from '../components/Membership';
import Auth from '../components/Auth';
import Tables from '../components/Tables';
import HeaderMin from '../components/HeaderMin';
import ButtonGradient from "../assets/svg/ButtonGradient";
import Benefits from "../components/Benefits";
import Collaboration from "../components/Collaboration";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import Pricing from "../components/Pricing";
import Roadmap from "../components/Roadmap";
import Services from "../components/Services";
import OverlaidElements from '../components/OverlaidElements';
import UsersList from '../components/UsersList';

const AdminPortal = () => {
  const { user, isAuthenticated, logout } = useContext(AuthContext);
  return (
    <div className="pt-[4.75rem] lg:pt-[5.25rem] overflow-hidden">
        
      
        {isAuthenticated ? (
        <div>
          {user.isAdmin && (
                  <div>
                <HeaderMin />
                <UsersList />
                <TableProvider>
                <Collaboration />
                </TableProvider>
                <Footer />
                </div>
                )}
                
              </div >
            ) : (
              <div style={{'justifyContent': 'center', 'alignItems': 'center', 'textAlign': 'center', 'fontSize': '2rem'}}>
                THIS PAGE IS ONLY ACCESSIBLE FOR BILLIARD LOUNGE STAFF AND ADMIN.
                
                </div>
            )}
            
            
      
      
      </div>
  );
};

export default AdminPortal;
