import React, { useContext } from 'react';
import AuthContext from '../context/AuthContext';
import TableContext, { TableProvider } from '../context/TableContext';
import Membership from '../components/Membership';
import Auth from '../components/Auth';
import Tables from '../components/Tables';
import Header from '../components/Header';
import ButtonGradient from "../assets/svg/ButtonGradient";
import Benefits from "../components/Benefits";
import Collaboration from "../components/Collaboration";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import Pricing from "../components/Pricing";
import Roadmap from "../components/Roadmap";
import Services from "../components/Services";
import OverlaidElements from '../components/OverlaidElements';
import LoginModal from '../components/Login';
import LoadingSpinner from '../components/LoadingSpinner';


const Home = () => {
  const { user, isAuthenticated, logout, loading } = useContext(AuthContext);

  return (
    <div className="pt-[4.75rem] lg:pt-[5.25rem] overflow-hidden">
        {loading ? <LoadingSpinner /> : (
        <div>
          <Header />
        
        <Hero />
        <Benefits />
        <TableProvider>
        <OverlaidElements />
        <Collaboration />
        </TableProvider>
        {/* <Services /> */}
        {/* {isAuthenticated ? (
          <Membership />
        ):(
          <Pricing />
        )} */}
        <Pricing />
        <Roadmap />
        <Footer />
          
        </div>
      )}
        
      

      <ButtonGradient />
      
    </div>
  );
};

export default Home;
