import React, { useContext, useState } from 'react';
import AuthContext from '../context/AuthContext';
import {auth} from '../firebase'
import { toast } from 'react-toastify';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { brainwaveSymbol, heroBackground } from '../assets/index'
import ForgotPasswordModal from './ForgotPasswordModal';
import Section from './Section';



const LoginForm = ( { closeModal } ) => {
  const [errorMessage, setErrorMessage] = useState("");
  const { register, login, isAuthenticated, notification, loginError } = useContext(AuthContext);
  const [isRegistering, setIsRegistering] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isForgotPasswordModalOpen, setIsForgotPasswordModalOpen] = useState(false);


    const handleSubmit = async (e) => {
    e.preventDefault();
    if (isRegistering) {
      register(name, email, password);
    } else {
      login(email, password);
    }
  };

  const openForgotPasswordModal = () => {
    setIsForgotPasswordModalOpen(true);
  };

  const closeForgotPasswordModal = () => {
    setIsForgotPasswordModalOpen(false);
  };



  
  return (
    <Section>
      
    <div className='loginFormContainer'>
      
              
    <div style={{'display': 'block',  'marginLeft': 'auto', 'marginRight': 'auto'}}><img src={brainwaveSymbol} width={134} height={28} alt={brainwaveSymbol} /></div>
             <div className='title'>
             
             <h1>{isRegistering ? 'Register' : 'Login'} </h1>
             
             </div>

    <div className="body">




    {notification ? (
      <>
      <div style={{'color': 'orange'}}>
        {notification}
      </div>
      <div><a href="/"> &nbsp; Click here to go back</a></div>
      </>
    ) : 


      <div className='credsInModal'>
      <p style={{ color: 'red' }}>{handleSubmit.error}</p>
      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        <form onSubmit={handleSubmit}>
          {isRegistering && (
            <div className='credstext'>
              
              {/* <div>Name</div> */}
              <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Name"
            />
            </div>
            
          )}
          <div className='credstext'>
            <div>Email</div> 
            
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="john_doe@gmail.com"
          />
          </div>
          <div className='credstext'>
             <div>Password</div> 
             <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="password"
                    pattern=".{7,}"
                    title="Password must be at least 7 characters long."
                    required   

                  />
          </div>
          <div style={{ textAlign: 'center' }}>
                  <button
                    type="button"
                    onClick={openForgotPasswordModal}
                    style={{ cursor: 'pointer', fontSize: '1rem', color: 'cornflowerblue' }}
                  >
                    Forgot Password?
                  </button>
                </div>
          <div className='footer'>
          <button  type="submit">{isRegistering ? 'Register' : 'Login'}</button>
                 
             </div>

             <div>
        {loginError  ? (
                <div style={{'color': 'red'}}>
                {loginError}
                </div>
              ):null}
        </div>
          <a style={{'cursor': 'pointer', 'fontSize': '1rem', 'color': 'cornflowerblue'}} href='/signup'>
        {isRegistering ? 'Already have an account? Login' : 'Don’t have an account? Register'}
      </a>
          
        </form>
        
      </div>

        }


    </div>
    
    
    </div>
    <ForgotPasswordModal isOpen={isForgotPasswordModalOpen} onRequestClose={closeForgotPasswordModal} />
    </Section>
    
  );
};

export default LoginForm;
