import Section from "./Section";
import { check } from "../assets";
import React, { useContext, useEffect, useState } from 'react';
import { smallSphere, stars } from "../assets";
import Heading from "./Heading";
import PricingList from "./PricingList";
import { LeftLine, RightLine } from "./design/Pricing";
import AuthContext from '../context/AuthContext';
import Membership from "./Membership";

const Pricing = () => {

  function toUpperCaseIfDefined(str) {
    return str ? str.toUpperCase() : str;
  }

  
  const { user, isAuthenticated } = useContext(AuthContext);
  return (
    <Section className="overflow-hidden" id="pricing">
      <div className="container relative z-2">
        <div className="hidden relative justify-center mb-[6.5rem] lg:flex">
          <img
            src={smallSphere}
            className="relative z-1"
            width={255}
            height={255}
            alt="Sphere"
          />
          <div className="absolute top-1/2 left-1/2 w-[60rem] -translate-x-1/2 -translate-y-1/2 pointer-events-none">
            <img
              src={stars}
              className="bounce2"
              width={950}
              height={400}
              alt="Stars"
            />
          </div>
        </div>

        {isAuthenticated ? (
          <div>
          <div className="lg: text-[2.5rem] sm: text-[2rem] md: text-[2.5rem]" style={{'textAlign': 'center'}}>Welcome back {user.name}!</div>

          <div className="relative">
            
          {/* <div className="flex-center gap-[1rem] max-lg:flex-wrap"> */}
          <div className="max-lg:flex-wrap">
            
              <div
                key="0"
                className="w-[19rem] max-lg:w-full h-full px-6 bg-n-8 border border-n-6 rounded-[2rem] lg:w-auto even:py-14 odd:py-8 odd:my-4 [&>h4]:first:text-color-2 [&>h4]:even:text-color-1 [&>h4]:last:text-color-3"
              >
                

                <p className="body-2 mb-3 text-n-1/50">
                  Current Plan
                </p>
                <div className="flex items-center h-[5.5rem] mb-6">

                    <>
                      {/* <div className="h3">$</div> */}
                      <div className="memberPlan leading-none font-bold">
                      {toUpperCaseIfDefined(user.membershipType) || 'FREE'}
                      {/* {user.membershipType || 'FREE'} */}
                      </div>
                    </>
                  
                </div>
                <h4 className="h4 mb-4">Till {user.membershipExpiration || '0-0-0'}</h4>
                <ul>
                    <li
                      key="0"
                      className="flex items-start py-5 border-t border-n-6"
                    >
                      <img src={check} width={24} height={24} alt="Check" />
                      <p className="body-2 ml-4">Priority Online Reservation Available <span style={{'fontWeight': '900', 'color': 'gold'}}>(PLATINUM MEMBERS ONLY)</span></p>
                    </li>
                    <li
                      key="1"
                      className="flex items-start py-5 border-t border-n-6"
                    >
                      <img src={check} width={24} height={24} alt="Check" />
                      <p className="body-2 ml-4">Online Reservation Available</p>
                    </li>
                    <li
                      key="2"
                      className="flex items-start py-5 border-t border-n-6"
                    >
                      <img src={check} width={24} height={24} alt="Check" />
                      <p className="body-2 ml-4">Table Rate: 22$/hour & 2$ for additional person excluding TAX (upto 6 people allowed on a single table)</p>
                    </li>
                </ul>
                <div className="flex justify-center mt-10">
            <a
              className="text-lg font-code font-bold tracking-wider uppercase border-b"
              href="/upgrade"
            >
              Upgrade?
            </a>
          </div>
              </div>
              
          </div>
            
            <LeftLine />
            <RightLine />
          </div>

          
          </div>


        ):(
          <div>
          <Heading
              tag=""
              title="Become a Member & Join the Community"
            />

            <div className="relative">
              <PricingList />
              <LeftLine />
              <RightLine />
            </div>

            <div className="flex justify-center mt-10">
              <a
                className="text-xs font-code font-bold tracking-wider uppercase border-b"
                href="/pricing"
              >
                See the full details
              </a>
            </div>
          </div>
        )}

        
        </div>
    </Section>
  );
};

export default Pricing;


