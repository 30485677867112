import React, { useContext, useEffect, useState } from 'react';
import TableContext from '../context/TableContext';
import { format, toZonedTime } from 'date-fns-tz';

const OverlaidElements = ({ className }) => {
  const { tables } = useContext(TableContext);
  const [freeTableCount, setFreeTableCount] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const timeZone = 'America/Toronto';
  const formatString = 'yy-MM-dd - HH:mm';

  const [currentGlobalTime, setCurrentGlobalTime] = useState(null);

  useEffect(() => {
    if (tables.length === 0) {
      // If there are no tables available at all (indicating no timeslots for current time), the lounge is closed
      setFreeTableCount(0);
      setIsLoaded(true);
    } else {
      // Calculate the number of free tables
      const countFreeTables = tables.filter((table) => table.status === 'free').length;
      setFreeTableCount(countFreeTables);
      setIsLoaded(true);
    }
  }, [tables]);

  useEffect(() => {
    const updateTime = () => {
      const now = new Date();
      const zonedDate = toZonedTime(now, timeZone);
      const formattedTZDate = format(zonedDate, formatString, { timeZone });
      setCurrentGlobalTime(formattedTZDate);
    };

    const interval = setInterval(() => {
      updateTime();
    }, 5000); // Update every 5 seconds
    updateTime();
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div
        style={{
          backgroundColor: '#ffffff50',
          position: 'fixed',
          zIndex: '100',
          bottom: '2%',
          right: '2%',
          borderRadius: '22% / 90%',
          padding: '15px',
          textShadow: '1px 1px 2px black, 1px 1px black, 3px 3px 1px black',
        }}
      >
        {!isLoaded ? (
          <>
            Loading Tables
            <span
              className="fading-in-out"
              style={{
                color: 'red',
                fontWeight: '900',
              }}
            >
              {' '}
              &nbsp; • LIVE
            </span>
          </>
        ) : tables.length === 0 ? (
          // If tables array length is 0, it indicates the lounge is closed (no timeslots for the current time)
          <>
            Closed
            <span
              className="fading-in-out"
              style={{
                color: 'red',
                fontWeight: '900',
              }}
            >
              {' '}
              &nbsp; • LIVE •
            </span>
          </>
        ) : (
          <>
            {freeTableCount} {freeTableCount === 1 ? 'Table' : 'Tables'} Free
            <span
              className="fading-in-out"
              style={{
                color: 'red',
                fontWeight: '900',
              }}
            >
              {' '}
              &nbsp; • LIVE •
            </span>
          </>
        )}
      </div>
      <div
        style={{
          backgroundColor: '#ffffff50',
          position: 'fixed',
          zIndex: '100',
          bottom: '2%',
          left: '2%',
          borderRadius: '35% / 60%',
          padding: '15px',
          textShadow: '1px 1px 2px black, 1px 1px black, 3px 3px 1px black',
        }}
      >
        {!isLoaded ? (
          <>Loading Time</>
        ) : (
          <>
            {currentGlobalTime && (
              <span style={{ fontWeight: '900', color: 'white' }}>
                {' '}
                {JSON.stringify(currentGlobalTime).split(' - ')[1].replace('"', '')}{' '}
              </span>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default OverlaidElements;
