import {
  benefitIcon1,
  benefitIcon2,
  benefitIcon3,
  benefitIcon4,
  benefitImage2,
  chromecast,
  disc02,
  discord,
  discordBlack,
  facebook,
  figma,
  file02,
  framer,
  homeSmile,
  instagram,
  notification2,
  notification3,
  notification4,
  notion,
  photoshop,
  plusSquare,
  protopie,
  raindrop,
  recording01,
  recording03,
  roadmap1,
  roadmap2,
  roadmap3,
  roadmap4,
  searchMd,
  slack,
  sliders04,
  telegram,
  twitter,
  yourlogo,
} from "../assets";

export const navigation = [
  {
    id: "0",
    title: "Cafe / Menu",
    url: "#activities",
  },
  {
    id: "1",
    title: "Check Availability",
    url: "#tables",
  },
  {
    id: "2",
    title: "Memberships",
    url: "#pricing",
  },
  {
    id: "3",
    title: "Contact Us",
    url: "#contactus",
  },


];

export const heroIcons = [homeSmile, file02, searchMd, plusSquare];

export const notificationImages = [notification4, notification3, notification2];

export const companyLogos = [yourlogo, yourlogo, yourlogo, yourlogo, yourlogo];

export const brainwaveServices = [
  "Photo generating",
  "Photo enhance",
  "Seamless Integration",
];

export const brainwaveServicesIcons = [
  recording03,
  recording01,
  disc02,
  chromecast,
  sliders04,
];

export const roadmap = [
  {
    id: "0",
    title: "Estabilished",
    text: "Enable the chatbot to understand and respond to voice commands, making it easier for users to interact with the app hands-free.",
    date: "May 2022",
    status: "The Journey Begins",
    imageUrl: roadmap1,
    colorful: true,
  },
  {
    id: "1",
    title: "Our first 10k visits",
    text: "Add game-like elements, such as badges or leaderboards, to incentivize users to engage with the chatbot more frequently.",
    date: "Dec 2022",
    status: "Achievement",
    imageUrl: roadmap2,
  },
  {
    id: "2",
    title: "Started Expanding",
    text: "Allow users to customize the chatbot's appearance and behavior, making it more engaging and fun to interact with.",
    date: "May 2023",
    status: "Improvement",
    imageUrl: roadmap3,
  },
  {
    id: "3",
    title: "Online Presence",
    text: "Launched our online membership.",
    date: "June 2024",
    status: "Chapter 2",
    imageUrl: roadmap4,
  },
];

export const collabText =
  "All data shown to you is real-time and up to date.";

export const collabContent = [
  {
    id: "0",
    title: "Real-Time Tables Availability",
    text: collabText,
  }
  // {
  //   id: "1",
  //   title: "Smart Automation",
  // },
  // {
  //   id: "2",
  //   title: "Top-notch Security",
  // },
];

export const collabApps = [
  {
    id: "0",
    title: "Figma",
    icon: figma,
    width: 26,
    height: 36,
  },
  {
    id: "1",
    title: "Notion",
    icon: notion,
    width: 34,
    height: 36,
  },
  {
    id: "2",
    title: "Discord",
    icon: discord,
    width: 36,
    height: 28,
  },
  {
    id: "3",
    title: "Slack",
    icon: slack,
    width: 34,
    height: 35,
  },
  {
    id: "4",
    title: "Photoshop",
    icon: photoshop,
    width: 34,
    height: 34,
  },
  {
    id: "5",
    title: "Protopie",
    icon: protopie,
    width: 34,
    height: 34,
  },
  {
    id: "6",
    title: "Framer",
    icon: framer,
    width: 26,
    height: 34,
  },
  {
    id: "7",
    title: "Raindrop",
    icon: raindrop,
    width: 38,
    height: 32,
  },
];

export const pricing = [
  {
    id: "4",
    title: "Free",
    description: "Welcome Aboard",
    price: "",
    features: [
      "Online Reservation Available",
      "Ability to explore the app and its features without any cost",
      "$22/hour for 2 people, 2$ extra for additional person",
      
    ],
  },
  {
    id: "0",
    title: "Basic",
    description: "Great for Casual Players",
    price: "50",
    features: [
      "Everything in Free",
      "3 Months Membership",
      "$22/hour for 2 people, 2$ extra for additional person",
    ],
  },
  {
    id: "1",
    title: "Gold",
    description: "Perfect for Groups & Events",
    price: "100",
    features: [
      "Everything in Basic",
      "6 Months Membership",
      "$22/hour for 2 people, 2$ extra for additional person",
      "5% discount on cafe/extra activities.",
    ],
  },
  {
    id: "2",
    title: "Platinum",
    description: "Unleash the VIP: Exclusive Billiard Experience Awaits.",
    price: "200",
    features: [
      "Everything in Gold",
      "12 Months Membership",
      "$22/hour for 2 people, no extra charges for additional person",
      "10% discount on cafe/extra activities.",
      "Priority support",
      
    ],
  },
  
];

export const membersUpgrade = [
  {
    id: "0",
    itemPlan: 1,
    membershipType: "basic",
    expiry: 3,
    title: "Silver",
    description: "Great for Casual Players",
    price: "30",
    features: [
      "3 Months Membership",
      "$20.90/hour for 2 people (5% discount)",
      "2$ per additional person",
      "Reservations upto 2 days ahead of time",
    ],
  },
  {
    id: "1",
    itemPlan: 2,
    membershipType: "gold",
    expiry: 3,
    title: "Gold",
    description: "Perfect for Groups & Events",
    price: "50",
    features: [
      "3 Months Membership",
      "$19.80/hour for 2 people (10% discount)",
      "1$ per additional person",
      "Reservations upto 3 days ahead of time",
    ],
  },
  {
    id: "2",
    itemPlan: 3,
    membershipType: "platinum",
    expiry: 6,
    title: "Platinum",
    description: "Unleash the VIP: Exclusive Billiard Experience Awaits.",
    price: "100",
    features: [
      "6 Months Membership",
      "$18.70/hour for 2 people (15% discount)",
      "1$ per additional person",
      "Reservations upto 5 days ahead of time",
      "Priority support",
    ],
  },
  
];



export const benefits = [
  {
    id: "0",
    title: "Billiards",
    text: "Challenge your friends to a game of billiards in our relaxed atmosphere.",
    backgroundUrl: "./src/assets/benefits/card-1.svg",
    iconUrl: benefitIcon1,
    imageUrl: benefitImage2,
  },
  {
    id: "1",
    title: "Social Lounge",
    text: "Host a birthday party or celebrate a special occasion with your friends at the lounge.",
    backgroundUrl: "./src/assets/benefits/card-2.svg",
    iconUrl: benefitIcon2,
    imageUrl: benefitImage2,
    light: true,
  },
  {
    id: "2",
    title: "Cafe",
    text: "Catch up with friends over drinks and snacks from the cafe.",
    backgroundUrl: "./src/assets/benefits/card-3.svg",
    iconUrl: benefitIcon3,
    imageUrl: benefitImage2,
  },
  {
    id: "3",
    title: "Relax & Socialize",
    text: "Cheer on your favorite team during a televised game on the TVs.",
    backgroundUrl: "./src/assets/benefits/card-4.svg",
    iconUrl: benefitIcon4,
    imageUrl: benefitImage2,
    light: true,
  },
  {
    id: "4",
    title: "Make Friends",
    text: "Team up for a friendly doubles match against another group.",
    backgroundUrl: "./src/assets/benefits/card-5.svg",
    iconUrl: benefitIcon1,
    imageUrl: benefitImage2,
  },
  {
    id: "5",
    title: "Improve everyday",
    text: "Practice your cue skills and perfect your break shot.",
    backgroundUrl: "./src/assets/benefits/card-6.svg",
    iconUrl: benefitIcon2,
    imageUrl: benefitImage2,
  },
];

export const socials = [
  {
    id: "0",
    title: "Discord",
    iconUrl: discordBlack,
    url: "#",
  },
  {
    id: "1",
    title: "Instagram",
    iconUrl: instagram,
    url: "#",
  },

  {
    id: "2",
    title: "Facebook",
    iconUrl: facebook,
    url: "#",
  },
];
