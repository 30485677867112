import React, { useRef, useEffect, useState } from 'react';

const PayPalButton = ({ amount, onSuccess }) => {
  const paypalRef = useRef();
  const [isRendered, setIsRendered] = useState(false);

  // Convert amount to a number
  const numericAmount = parseFloat(amount);
  
  // Calculate the final amount including the fee
  const fee = (numericAmount * 0.0305) + 0.30;
  const finalAmount = (numericAmount + fee).toFixed(2);

  useEffect(() => {
    if (!isRendered) {
      window.paypal.Buttons({
        createOrder: (data, actions) => {
          return actions.order.create({
            purchase_units: [{
              amount: {
                value: finalAmount,
              },
            }],
            application_context: {
              shipping_preference: 'NO_SHIPPING', // Disable shipping address fields
            },
          });
        },
        onApprove: async (data, actions) => {
          const order = await actions.order.capture();
          onSuccess(order);
        },
        onError: (err) => {
          console.error(err);
        },
      }).render(paypalRef.current);
      setIsRendered(true);
    }
  }, [finalAmount, onSuccess, isRendered]);

  return (
    <>
      <div style={{ marginBottom: '35px', color: 'green', fontWeight: '900',   }}>
        <div style={{ marginBottom: '5px' }}>After Transaction Fees You Pay </div>
        <div style={{ fontSize: '2rem' }}>${finalAmount}</div>
      </div>
      <div ref={paypalRef}></div>
    </>
  );
};

export default PayPalButton;
