import React, { useState, useEffect, useContext } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { db } from '../firebase';
import { collection, getDocs, addDoc } from 'firebase/firestore';
import AuthContext from '../context/AuthContext';
import TableContext from '../context/TableContext';
import BookingModal from './BookingModal';
import PayPalButton from './PayPalButton';

const localizer = momentLocalizer(moment);

const WeeklyCalendar = () => {
  const { user } = useContext(AuthContext);
  const { updateTableStatus } = useContext(TableContext);
  const [events, setEvents] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [duration, setDuration] = useState(1);
  const [additionalPersons, setAdditionalPersons] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  
  useEffect(() => {
    const fetchBookings = async () => {
      const bookingsCollection = collection(db, 'bookings');
      const bookingsSnapshot = await getDocs(bookingsCollection);
      const bookingsList = bookingsSnapshot.docs.map(doc => ({
        ...doc.data(),
        start: new Date(doc.data().start),
        end: new Date(doc.data().end),
      }));
      setEvents(bookingsList);
    };

    fetchBookings();
  }, []);

  const handleSelectSlot = ({ start, end }) => {
    setSelectedSlot({ start, end });
    setIsModalOpen(true);
  };

  const handlePayPalSuccess = async (order) => {
    try {
      // Save booking to Firestore
      await addDoc(collection(db, 'bookings'), {
        userId: user.email + " - " + user.name,
        start: selectedSlot.start,
        end: new Date(selectedSlot.start.getTime() + duration * 60 * 60 * 1000),
        additionalPersons,
      });

      // Update table status
      await updateTableStatus('booked', duration, {
        id: user.uid,
        name: user.name,
        email: user.email,
      });

      // Update events state
      setEvents(prevEvents => [
        ...prevEvents,
        {
          start: selectedSlot.start,
          end: new Date(selectedSlot.start.getTime() + duration * 60 * 60 * 1000),
          title: `Booked by ${user.name}`,
        },
      ]);

      setIsModalOpen(false);
    } catch (error) {
      console.error('Error finalizing booking:', error);
    }
  };

  return (
    <div>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
        selectable
        onSelectSlot={handleSelectSlot}
        defaultView="week"
      />
      {/* <BookingModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div>
          <label>
            Duration:
            <button onClick={() => setDuration(prev => Math.max(prev - 1, 1))}>-</button>
            {duration} hours
            <button onClick={() => setDuration(prev => Math.min(prev + 1, user.membershipType === 'platinum' ? 10 : user.membershipType === 'gold' ? 5 : 3))}>+</button>
          </label>
          <label>
            Additional Persons:
            <button onClick={() => setAdditionalPersons(prev => Math.max(prev - 1, 0))}>-</button>
            {additionalPersons}
            <button onClick={() => setAdditionalPersons(prev => Math.min(prev + 1, 4))}>+</button>
          </label>
        </div>
        <PayPalButton
          amount={(24.86 * duration + 2.1 * additionalPersons).toFixed(2)}
          onSuccess={handlePayPalSuccess}
        />
      </BookingModal> */}
    </div>
  );
};

export default WeeklyCalendar;
