import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { db } from '../firebase';
import { doc, getDoc, setDoc, updateDoc, onSnapshot, runTransaction } from 'firebase/firestore';
import AuthContext from '../context/AuthContext';
import ScheduleModal from './ScheduleModal';
import PayPalButton from './PayPalButton';
import CustomDateButton from './CustomDateButton';
import { format, toZonedTime } from 'date-fns-tz';
import { Button } from '@mui/material';

const TimeSlotBooking = () => {
  const { user } = useContext(AuthContext);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTable, setSelectedTable] = useState(null);
  const [slots, setSlots] = useState({});
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [selectedSlotValue, setSelectedSlotValue] = useState(null);
  const [duration, setDuration] = useState(1);
  const [additionalPersons, setAdditionalPersons] = useState(0);
  const [bookedMessage, setBookedMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showTables, setShowTables] = useState(false);
  const [showSlots, setShowSlots] = useState(false);
  const [showBookingInfo, setShowBookingInfo] = useState(false);
  // const [availabilitySummary, setAvailabilitySummary] = useState([]);
  const [openAccordion, setOpenAccordion] = useState(null);
  // const [currentAvailabilityIndex, setCurrentAvailabilityIndex] = useState(0); // New state for date index
  const [adminName, setAdminName] = useState('');
  const [adminEmail, setAdminEmail] = useState('');
  const [adminPhone, setAdminPhone] = useState('');
  const [formError, setFormError] = useState('');

  const toggleAccordion = (date) => {
    setOpenAccordion((prevDate) => (prevDate === date ? null : date));
  };

  const timeZone = 'America/Toronto';
  // const formatString = 'yyyy-MM-dd HH:mm:ssXXX';
  const formatString = 'yy-MM-dd - HH:mm';
  const now = new Date();
  const zonedDate = toZonedTime(now, timeZone);
  const zonedDatePlusOneHour = new Date(zonedDate);
  zonedDatePlusOneHour.setHours(zonedDatePlusOneHour.getHours() + 1);
  const formattedTZDate = format(zonedDate, formatString, { timeZone });

  const tables = Array.from({ length: 12 }, (_, i) => `Table ${i + 1}`);


  const [time, setTime] = useState(toZonedTime);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(new Date()); // Update the current time state every second
    }, 1000);

    return () => clearInterval(intervalId); // Cleanup the interval on unmount
  }, []);




  const sendBookingNotification = async (bookingDetails) => {
    const apiUrl = 'https://go6i8dof5g.execute-api.us-east-2.amazonaws.com/BilliardLoungeREST/sendBookingNotification';
  
    try {
      const response = await axios.post(apiUrl, bookingDetails, {
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': process.env.REACT_APP_AWS_CALL_API_KEY,
        },
      });
  
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };




  useEffect(() => {
    let unsubscribe;

    if (selectedDate && selectedTable) {
      const dateStr = selectedDate.toISOString().split('T')[0];
      const docRef = doc(db, 'scheduledBookings', dateStr);

      function formatDateToYYYYMMDD(date) {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); 
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
      }

      const now = zonedDate;
      const currentHour = now.getHours() + 1;
      const formattedDate = formatDateToYYYYMMDD(now);
      const formattedSelectedDate = formatDateToYYYYMMDD(selectedDate);



      unsubscribe = onSnapshot(docRef, (docSnap) => {
        if (formattedDate === formattedSelectedDate) {
          if (docSnap.exists()) {
            const allSlots = docSnap.data();
            const filteredSlots = Object.keys(allSlots)
              .filter(key => {
                const slotHour = parseInt(key.split(', ')[1].split(':')[0], 12);
                return slotHour > currentHour + 1;
              })
              .reduce((result, key) => {
                let slotValue = allSlots[key];
                if (slotValue.includes('temporary')) {
                  const tempTime = new Date(slotValue.split(', ')[3]);
                  if (zonedDate > tempTime) {
                    slotValue = 'free';
                  }
                }
                result[key] = slotValue;
                return result;
              }, {});
            setSlots(filteredSlots);
          }
        } else {
          if (docSnap.exists()) {
            setSlots(docSnap.data());
          } else {
            const initialSlots = {};
            for (let i = 1; i <= 12; i++) {
              const table = `Table ${i}`;
              for (let hour = 12; hour <= 26; hour++) {
                const slotHour = hour > 24 ? hour - 24 : hour;
                const slotTime = `${slotHour.toString().padStart(2, '0')}:00`;
                initialSlots[`${table}, ${slotTime}`] = 'free';
              }
            }
            setDoc(docRef, initialSlots);
            setSlots(initialSlots);
          }
        }
      });
    } else {
      setSlots({});
    }

     return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [selectedDate, selectedTable]);

  // Set up an interval to check current time every second and remove expired slots
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (selectedDate) {
        const currentDate = zonedDate
        const selectedDateString = selectedDate.toDateString();
        const currentDateString = currentDate.toDateString();

        // If the selected date is today, filter out expired time slots
        if (selectedDateString === currentDateString) {
          setSlots((prevSlots) => {
            const filteredSlots = Object.entries(prevSlots).reduce((result, [key, value]) => {
              const time = key.split(', ')[1];
              const [hour, minute] = time.split(':').map(Number);

              // Only include slots that haven't passed yet
              if (
                hour > currentDate.getHours() ||
                (hour === currentDate.getHours() && minute > currentDate.getMinutes())
              ) {
                result[key] = value;
              }

              return result;
            }, {});

            return filteredSlots;
          });
        }
      }
    }, 1000); // Update every second

    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, [selectedDate]);

  const resetBookingState = () => {
    setSelectedSlot(null);
    setSelectedTable(null);
    setSelectedDate(null);
    setShowSlots(false);
    setShowTables(false);
    setDuration(1);
    setSlots({}); 
  };

  const freeTemporarilyBooked = async () => {
    const dateStr = selectedDate.toISOString().split('T')[0];
    const docRef = doc(db, 'scheduledBookings', dateStr);

    try {
      await runTransaction(db, async (transaction) => {
        const docSnap = await transaction.get(docRef);
        if (!docSnap.exists()) {
          throw 'Document does not exist!';
        }
        const data = docSnap.data();
        let startHour = parseInt(selectedSlot.split(':')[0], 10);

        for (let i = 0; i < duration; i++) {
          const hour = startHour + i;
          const slotTime = `${hour.toString().padStart(2, '0')}:00`;
          const slotKey = `${selectedTable}, ${slotTime}`;
          const slotValue = data[slotKey];

          if (slotValue.includes('temporary') && slotValue.includes(user.email)) {
            transaction.update(docRef, {
              [slotKey]: 'free',
            });
          }
        }
      });
    } catch (error) {
      console.error('Failed to free temporarily booked slots:', error);
    }
  };

  const calculateDiscountedAmountBeforeTax = () => {
    
    let hourlyRateBeforeTax = 22;
    let additionalPersonRate = 2.1;

    if (selectedDate) {
      const dayOfWeek = selectedDate.getDay(); 
      const selectedHour = parseInt(selectedSlot?.split(':')[0], 10); 
  
      if (dayOfWeek >= 1 && dayOfWeek <= 4 && selectedHour >= 12 && selectedHour <= 18) {
        hourlyRateBeforeTax = 15;
      }
    }
  
    if (user.membershipType === 'gold') {
      hourlyRateBeforeTax *= 0.90; 
      additionalPersonRate = 1; 
    } else if (user.membershipType === 'platinum') {
      hourlyRateBeforeTax *= 0.85; 
      additionalPersonRate = 1; 
    } else if (user.membershipType === 'basic') {
      hourlyRateBeforeTax *= 0.95; 
    } else {
      hourlyRateBeforeTax *= 1;
      additionalPersonRate = 2;
    }
  
    return (hourlyRateBeforeTax * duration + additionalPersonRate * additionalPersons).toFixed(2);
  };

  const calculateDiscountedAmount = () => {
    
    let hourlyRate = 24.86;
    let hourlyRateBeforeTax = hourlyRate / 1.13
    let additionalPersonRate = 2.1;

    if (selectedDate) {
      const dayOfWeek = selectedDate.getDay(); 
      const selectedHour = parseInt(selectedSlot?.split(':')[0], 10); 
  
      if (dayOfWeek >= 1 && dayOfWeek <= 4 && selectedHour >= 12 && selectedHour <= 18) {
        hourlyRate = 16.95;
      }
    }
  
    if (user.membershipType === 'gold') {
      hourlyRate *= 0.90; 
      additionalPersonRate = 1.1; 
    } else if (user.membershipType === 'platinum') {
      hourlyRate *= 0.85; 
      additionalPersonRate = 1.1; 
    } else if (user.membershipType === 'basic') {
      hourlyRate *= 0.95; 
    } else {
      hourlyRate *= 1;
      additionalPersonRate = 2.1;
    }
  
    return (hourlyRate * duration + additionalPersonRate * additionalPersons).toFixed(2);
  };
  



  const handleTemporaryBooking = async () => {
    const dateStr = selectedDate.toISOString().split('T')[0];
    const docRef = doc(db, 'scheduledBookings', dateStr);
    const temporaryBookingTime = new Date(zonedDate.getTime() + 2 * 60000); // 2 minutes from now
  
    try {
      await runTransaction(db, async (transaction) => {
        const docSnap = await transaction.get(docRef);
        if (!docSnap.exists()) {
          throw 'Document does not exist!';
        }
        const data = docSnap.data();
        let startHour = parseInt(selectedSlot.split(':')[0], 10);
  
        for (let i = 0; i < duration; i++) {
          const hour = startHour + i;
          const slotTime = `${hour.toString().padStart(2, '0')}:00`;
          const slotKey = `${selectedTable}, ${slotTime}`;
          const slotValue = data[slotKey];
  
          if (slotValue !== 'free') {
            throw 'Slot is not available';
          }
  
          transaction.update(docRef, {
            [slotKey]: `free, ${user.email}, temporary, ${temporaryBookingTime.toISOString()}`,
          });
        }
      });
      setIsModalOpen(true);
  
      setTimeout(() => {
        closeModal();
      }, 2 * 60000);
    } catch (error) {
      alert('Failed to temporarily book the slot. Please try again.');
      // console.log(error)
    }
  };
  
  
  const handleBooking = async () => {
    const dateStr = selectedDate.toISOString().split('T')[0];
    const docRef = doc(db, 'scheduledBookings', dateStr);
  
    try {
      await runTransaction(db, async (transaction) => {
        const docSnap = await transaction.get(docRef);
        if (!docSnap.exists()) {
          throw 'Document does not exist!';
        }
        const data = docSnap.data();
        let startHour = parseInt(selectedSlot.split(':')[0], 10);
  
        for (let i = 0; i < duration; i++) {
          const hour = startHour + i;
          const slotTime = `${hour.toString().padStart(2, '0')}:00`;
          const slotKey = `${selectedTable}, ${slotTime}`;
          const slotValue = data[slotKey];

          if (!slotValue.includes('free')) {
            throw 'Slot is no longer available or has been booked by someone else.';
          }
  
          transaction.update(docRef, {
            [slotKey]: `booked, ${user.email}`,
          });
        }
      });

      
      const bookingDetails = {
        userName: user.name,
        userEmail: user.email,
        selectedTable,
        selectedHour: selectedSlot,
        selectedDate: selectedDate.toDateString(),
        duration,
        amount: calculateDiscountedAmount(),
      };
      // console.log('booking:', bookingDetails);

      await sendBookingNotification(bookingDetails);
      // console.log('bookings notification called');

      setBookedMessage(
        `You booked ${selectedSlot} on ${selectedDate.toDateString()} for ${duration} hour(s) at ${selectedTable}`
      );
      setIsModalOpen(false);
      resetBookingState();
    } catch (error) {
      alert('Failed to book the slot. Please try again.');
      // console.log(error);
      setIsModalOpen(false);
    }
  };

  const handleBookingAdmin = async (adminName, adminEmail, adminPhone) => {
    const dateStr = selectedDate.toISOString().split('T')[0];
    const docRef = doc(db, 'scheduledBookings', dateStr);
    const contactInfo = `${adminName || ''}_${adminEmail || ''}_${adminPhone || ''}`;


    try {
      await runTransaction(db, async (transaction) => {
        const docSnap = await transaction.get(docRef);
        if (!docSnap.exists()) {
          throw 'Document does not exist!';
        }
        const data = docSnap.data();
        let startHour = parseInt(selectedSlot.split(':')[0], 10);

        for (let i = 0; i < duration; i++) {
          const hour = startHour + i;
          const slotTime = `${hour.toString().padStart(2, '0')}:00`;
          const slotKey = `${selectedTable}, ${slotTime}`;
          const slotValue = data[slotKey];

          if (!slotValue.includes('free')) {
            throw 'Slot is no longer available or has been booked by someone else.';
          }

          transaction.update(docRef, {
            [slotKey]: `booked, ${contactInfo}`,
          });
        }
      });

      const bookingDetails = {
        userName: adminName || 'Admin',
        userEmail: adminEmail || 'N/A',
        userPhone: adminPhone || 'N/A',
        selectedTable,
        selectedHour: selectedSlot,
        selectedDate: selectedDate.toDateString(),
        duration,
        amount: calculateDiscountedAmount(),
      };
      console.log('booking:', bookingDetails);

      await sendBookingNotification(bookingDetails);
      console.log('bookings notification called');

      setBookedMessage(
        `You booked ${selectedSlot} on ${selectedDate.toDateString()} for ${duration} hour(s) at ${selectedTable}`
      );
      setIsModalOpen(false);
      resetBookingState();
    } catch (error) {
      alert('Failed to book the slot. Please try again.');
      console.log(error);
      setIsModalOpen(false);
    }
  };

  const handleAdminBookingSubmission = () => {
    if (!adminName && !adminEmail && !adminPhone) {
      setFormError('Please fill in at least one contact field (name, email, or phone number).');
    } else {
      setFormError('');
      handleBookingAdmin(adminName, adminEmail, adminPhone);
    }
  };
  
  

  const readSelectedSlotValue = async (selectedDate, selectedTable, selectedSlot) => {
    const dateStr = selectedDate.toISOString().split('T')[0];
    const docRef = doc(db, 'scheduledBookings', dateStr);
    const docSnap = await getDoc(docRef);
  
    if (docSnap.exists()) {
      const allSlots = docSnap.data();
      const slotKey = `${selectedTable}, ${selectedSlot}`;
      // console.log("Read Selected Slot Value Called", allSlots[slotKey])
      return allSlots[slotKey] || 'free';
    } else {
      // console.log("Read Selected Slot Value Called FREE", allSlots[slotKey])
      return 'free';
    }

    
  };

  
  const openModal = () => {
    // console.log("selectedSlotValue", selectedSlotValue)
    readSelectedSlotValue(selectedDate, selectedTable, selectedSlot).then((value) => {
      setSelectedSlotValue(value);
      // console.log("value", value, "selectedSlotValue", selectedSlotValue)
      if (value.includes(user.email) || value === 'free') {
        handleTemporaryBooking();
        setIsModalOpen(true);
      } else {
        alert('This slot has been temporarily booked as someone is already proceeding payment on this slot, please select another one from the list');
        setIsModalOpen(false);
      }
    });
  };
  

  const closeModal = () => {
    freeTemporarilyBooked();
    setIsModalOpen(false);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setSelectedTable(null);
    setSelectedSlot(null);
    setShowTables(true);
    setShowSlots(false);
    setBookedMessage('');
    setSlots({}); // Reset slots
  };

  const handleTableChange = (table) => {
    setSelectedTable(table);
    setSelectedSlot(null);
    setShowSlots(true);
    // Optionally reset slots if needed
    // setSlots({});
  };

  const incrementDuration = () => {
    const maxDuration = getMaxDuration(selectedSlot);
    
    setDuration(prev => {
      if (prev + 1 > maxDuration) {
        alert("More hours not available, try another slot!");
        return prev; // Prevent increment beyond max duration
      }
      return prev + 1; // Increment duration if within the limit
    });
  };


  const refreshDuration = (slot) => {
    const maxDuration = getMaxDuration(slot);
    setDuration(prev => prev > maxDuration ? maxDuration : prev);
  };

  const decrementDuration = () => {
    setDuration(prev => Math.max(prev - 1, 1));
  };

  const incrementAdditionalPersons = () => {
    setAdditionalPersons(prev => Math.min(prev + 1, 4));
  };

  const decrementAdditionalPersons = () => {
    setAdditionalPersons(prev => Math.max(prev - 1, 0));
  };

  const getMaxDuration = (startSlot) => {
    if (!startSlot) return 1;
    const startHour = parseInt(startSlot.split(':')[0], 10);
    const maxEndHour = 27; // 3 AM in 24-hour format (27:00)
    let maxAvailableDuration = maxEndHour - startHour;

    for (let i = 1; i <= maxAvailableDuration; i++) {
      const hour = startHour + i;
      const slotTime = `${hour < 10 ? '0' : ''}${hour}:00`;
      if (slots[`${selectedTable}, ${slotTime}`] !== 'free') {
        maxAvailableDuration = i;
        break;
      }
    }

    const maxDurationByMembership = user.membershipType === 'platinum' ? 10 : user.membershipType === 'gold' ? 5 : 3;
    const maxAvailableDurationEnd = Math.min(maxAvailableDuration, maxDurationByMembership)
    
    
    if (duration == maxDurationByMembership) {
      alert("Please upgrade your membership to book more hours")
    } 

    
    
    return Math.min(maxAvailableDuration, maxDurationByMembership);
  };



  

  const generateDates = () => {
    const dates = [];
    const today = toZonedTime(now, timeZone);
    
    let maxDays;
    if (user.membershipType === 'platinum') {
      maxDays = 5;
    } else if (user.membershipType === 'gold') {
      maxDays = 3;
    } else if (user.membershipType === 'basic') {
      maxDays = 2;
    } else {
      maxDays = 0; 
    }
  
    for (let i = 0; i <= maxDays; i++) {
      const date = new Date(today);
      date.setDate(today.getDate() + i);
      dates.push(date);
    }
  
    return dates;
  };


  return (
    <div className="keep-in-view">
    

    <div className="booking-container">
      <div className="time-slot-booking">
      {bookedMessage &&
         <>
         <p style={{fontWeight: '900', fontSize: '1.3rem', padding: '10px', color: 'green'}}>{bookedMessage}
         </p>
         <p style={{fontWeight: '900', fontSize: '1.2rem', paddingBottom: '10px'}}>Want to make another booking?</p>
         </>}
        {!showTables && (
          <>
          
          <h2>Select a day to book!</h2>
          <div className="date-buttons">
            
            {generateDates().map(date => (
              <CustomDateButton
                key={date.toDateString()}
                date={date}
                isSelected={selectedDate && date.toDateString() === selectedDate}
                onClick={() => handleDateChange(date)}
              />
            ))}
          </div>
          </>
        )}
        {showTables && !showSlots && (
          <>
            <button className="back-button" onClick={() => setShowTables(false)}>Back</button>
            <h3>You selected {selectedDate.toDateString()}</h3>
            <h3>Select a Table</h3>
            <div className="table-selection">
              
              <div className="tables">
                {tables.map(table => (
                  <button
                    key={table}
                    onClick={() => handleTableChange(table)}
                    className={selectedTable === table ? 'selected' : ''}
                  >
                    {table}
                  </button>
                ))}
              </div>
            </div>
          </>
        )}
      
        {showSlots && !selectedSlot && (
          <>
            <button className="back-button" onClick={() => { setShowSlots(false); setSelectedSlot(null) }}>Back</button>
              <div>
                <h3>You're booking {selectedTable} on {selectedDate.toDateString()}</h3>
                <h3>Select a Time Slot</h3>
                <div className="slots">
                  {Object.keys(slots)
                    .filter(key => key.startsWith(`${selectedTable},`))
                    .sort((a, b) => {
                      const timeA = parseInt(a.split(', ')[1].split(':')[0], 10);
                      const timeB = parseInt(b.split(', ')[1].split(':')[0], 10);
                      return timeA - timeB;
                    })
                    .map(key => {
                      const time = key.split(', ')[1];
                      const [hour, minute] = time.split(':').map(Number);

                      // Exclude time slots between 3:00 AM and 3:00 PM
                      if (hour >= 3 && hour < 15) {
                        return null; // Skip these hours
                      }

                      const currentDate = zonedDate;
                      const selectedDateString = selectedDate.toDateString();
                      const currentDateString = currentDate.toDateString();

                      // Hide slots that have already passed on the current day
                      if (
                        selectedDateString === currentDateString &&
                        (hour < currentDate.getHours() ||
                          (hour === currentDate.getHours() && minute <= currentDate.getMinutes()))
                      ) {
                        return null;
                      }

                    return (
                      <button
                        key={key}
                        onClick={() => {
                          if (slots[key] === 'free') {
                            setSelectedSlot(time);
                            setSelectedSlotValue(slots[key]);
                            refreshDuration(time);
                          }
                        }}
                        className={`${selectedSlot === time ? 'selected' : ''} ${slots[key] !== 'free' ? 'unavailable' : ''}`}
                        disabled={slots[key] !== 'free'}
                      >
                        {time}
                      </button>
                    );
                  })}
              </div>
            </div>
          </>
        )}

        {selectedSlot && (
          <>
          {selectedSlotValue.includes(user.email) || selectedSlotValue === 'free' ? (
            <div>
              <div>
            <button className="back-button" onClick={() => {setSelectedSlot(null); setDuration(1)}}>Back</button>
            <h2>Booking Summary</h2>
            <h2 style={{ fontWeight: '900', fontSize: '1.3rem', padding: '10px' }}>
              Table {selectedTable} at {selectedSlot} for {duration} hour(s) on {selectedDate.toLocaleDateString()}
            </h2>

            <h2 style={{ fontWeight: '500', fontSize: '1rem', padding: '0px' }}>Base Price (before taxes):</h2>
            <h2 style={{ fontWeight: '900', fontSize: '1.6rem', padding: '0px' }}>
              ${calculateDiscountedAmountBeforeTax()}
            </h2>

            <h2 style={{ fontWeight: '500', fontSize: '1rem', padding: '0px' }}>Total (with tax & transaction fees):</h2>
            <h2 style={{ fontWeight: '900', fontSize: '2.2rem', padding: '5px' }}>
              ${(parseFloat(calculateDiscountedAmount()) * 1.0305 + 0.30).toFixed(2)}
            </h2>


            <div>
              <div>
              <label>Duration: &nbsp;
                <button style={{
              backgroundColor: '#007bff',
              color: 'black',
              padding: '5px 10px',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
            }} onClick={decrementDuration}>-</button> &nbsp;
                {duration} hours &nbsp;
                <button style={{
              backgroundColor: '#007bff',
              color: 'black',
              padding: '5px 10px',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
            }} onClick={incrementDuration}>+</button> &nbsp;
              </label>
              </div>
              <div>
              <label>Additional Persons: &nbsp;
                <button style={{
              backgroundColor: '#007bff',
              color: 'black',
              padding: '5px 10px',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
            }} onClick={decrementAdditionalPersons}>-</button> &nbsp;
                {additionalPersons} &nbsp;
                <button style={{
              backgroundColor: '#007bff',
              color: 'black',
              padding: '5px 10px',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
              marginTop: '5px',
            }} onClick={incrementAdditionalPersons}>+</button>
              </label>
              </div>
            </div>
            <button style={{
              backgroundColor: '#00ffc8',
              color: 'black',
              padding: '10px 20px',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
              marginTop: '20px',
              marginBottom: '20px',
            }} onClick={openModal}>Book & Pay</button>
          </div>
            </div>
          ) : (
            <div>
              <p>This slot is temporarily booked. Please select another slot.</p>
              <button style={{
              backgroundColor: '#00ffc8',
              color: 'black',
              padding: '10px 20px',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
              marginTop: '20px',
              marginBottom: '20px',
            }}
             onClick={() => { setSelectedSlot(null); setDuration(1); }}>Back</button>
            </div>
          )}
        </>
          
        )}

          <ScheduleModal isOpen={isModalOpen} onClose={() => closeModal()}>
            {user.isAdmin ? (
              <div>
                <div>
                  <label>
                    Name: <br />
                    <input
                      type="text"
                      value={adminName}
                      onChange={(e) => setAdminName(e.target.value)}
                      style={{ marginBottom: '10px', width: '100%', color: 'white' }}
                    />
                  </label>
                </div>
                <div>
                  <label>
                    Email: <br />
                    <input
                      type="email"
                      value={adminEmail}
                      onChange={(e) => setAdminEmail(e.target.value)}
                      style={{ marginBottom: '10px', width: '100%', color: 'white' }}
                    />
                  </label>
                </div>
                <div>
                  <label>
                    Phone Number: <br />
                    <input
                      type="text"
                      value={adminPhone}
                      onChange={(e) => setAdminPhone(e.target.value)}
                      style={{ marginBottom: '10px', width: '100%', color: 'white' }}
                    />
                  </label>
                </div>

                {formError && (
                  <p style={{ color: 'red', fontWeight: 'bold' }}>
                    {formError}
                  </p>
                )}

                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAdminBookingSubmission}
                  disabled={!adminName && !adminEmail && !adminPhone} 
                >
                  Book now!
                </Button>
              </div>
            ) : (
              <PayPalButton
                amount={calculateDiscountedAmount()}
                onSuccess={handleBooking}
              />
            )}
          </ScheduleModal>

      </div>
    </div>
    </div>
  );
};

export default TimeSlotBooking;
