import React, { useState } from 'react';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import './ForgotPasswordModal.css';

const ForgotPasswordModal = ({ isOpen, onRequestClose }) => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [isEmailSent, setIsEmailSent] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const auth = getAuth();

    try {
      await sendPasswordResetEmail(auth, email);
      setMessage('Password reset email sent! Check your inbox.');
      setError('');
      setIsEmailSent(true);
    } catch (error) {
      setError('Failed to send password reset email. Please check the email address and try again.');
      setMessage('');
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="modal-close" onClick={onRequestClose}>&times;</button>
        
        {isEmailSent ? (
            <>
            &nbsp;
          <div className="message-container">
            <p style={{ color: 'green' }}>{message}</p>
            <button className="modal-close-button" onClick={onRequestClose}>Close</button>
          </div>
          </>
        ) : (
            <>
            <h2>Forgot Password?</h2>
          <form onSubmit={handleSubmit} className="form-container">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              required
              className="email-input"
            />
            <button type="submit" className="submit-button">Send Password Reset Email</button>
            {error && <p style={{ color: 'red' }}>{error}</p>}
          </form>
          </>
        )}
      </div>
    </div>
  );
};

export default ForgotPasswordModal;
